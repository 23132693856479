import { create } from "zustand";

export const facebookModal = create((set) => ({
  openModal: false,
  accessToken: "",
  name: "",

  setOpenModal: (data) => {
    set(() => ({ openModal: data }));
  },
  setName: (data) => {
    set(() => ({ name: data }));
  },
  setAccessToken: (data) => {
    set(() => ({ accessToken: data }));
  },
}));
