import { _axios } from "interceptor/http-config";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { chatStore } from "store/chatStore";
export const useBlockUser = ({ chatID, setChatActions }) => {
  const { t } = useTranslation("chat");
  const [blockLoading, setBlockLoading] = useState(false);
  const queryClient = useQueryClient();
  const [setChatID] = chatStore((state) => [state.setChatID]);
  const blockToggle = useMutation(
    async () => {
      return _axios
        .get(`/user/conversation/toggle-block/${chatID}`)
        .then((response) =>
          enqueueSnackbar(response.data.message, {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          })
        );
    },
    {
      onMutate: async (id) => {
        await queryClient.cancelMutations("");
        return id;
      },
      onError: (error, variables, context) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const { message } = error.response.data;
          Object.keys(message).forEach((field) => {
            const errorMessages = message[field];
            enqueueSnackbar(errorMessages.join(", "), {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
            });
          });
        }
      },
      onSettled: (id, error, result) => {
        if (!error) {
          queryClient.invalidateQueries(["chat-blocked"]);
        }
        setBlockLoading(false);
        setChatActions(false);
      },
      onSuccess: (response) => {
        setChatActions(false);
        setChatID(undefined);
      },
    }
  );

  const handleBlock = async (id) => {
    setBlockLoading(true);
    await blockToggle.mutateAsync(id);
  };
  return {
    t,
    blockLoading,
    handleBlock,
  };
};
