import profileIcon from "assets/icons/navBar/profileIcon.png";
import backIcon from "assets/icons/svg/Back.svg";
import Loader from "components/styled/Loader";
import ImageModal from "../components/ImageModal/ImageModal";
import { useAttachment } from "../hooks/useAttachment";

const Attachment = () => {
  const {
    t,
    data,
    openModal,
    isLoading,
    closeModal,
    selectedImage,
    setAttachmentRes,
  } = useAttachment();
  return (
    <div className="flex flex-col shadow-md md:min-h-[1100px] w-[380px] md:w-full ">
      {isLoading && <Loader />}
      <div className="flex gap-4 p-3 lg:hidden">
        <button
          onClick={() => {
            setAttachmentRes(false);
          }}
        >
          <img src={backIcon} alt="" />
        </button>
        <div className="flex items-center gap-2">{t("Back")}</div>
      </div>
      <div className="w-full h-full ">
        <div className="flex flex-col gap-3 pt-4 rounded-md w-1/1">
          <div className="relative flex justify-center">
            <img
              src={data?.user_image}
              className="object-cover w-40 h-40 rounded-full -z-10"
              alt=""
            />
            <span className="absolute block w-3 h-3 rounded-full right-40 bg-fourth-color md:hidden"></span>
          </div>
          <div className="flex justify-center py-2 text-lg">
            <p>{data?.user_name}</p>
          </div>
          <div className="h-[1px] bg-third-color"></div>
        </div>
        <div className="p-3">
          <p className="text-[#484E5B] text-xl">{t("Attachment")}</p>
        </div>
        <div>
          <div className="grid grid-cols-2 2xl:grid 2xl:grid-cols-3 gap-5 p-3 h-1/1 place-items-center max-h-[400px] overflow-scroll">
            {data?.attachments.length > 0 ? (
              data.attachments.map((item, index) => (
                <img
                  key={index}
                  src={item}
                  alt="No Media"
                  className="object-cover w-40 h-32 cursor-pointer bg-third-color rounded-2xl"
                  onClick={() => openModal(item)}
                />
              ))
            ) : (
              <p>{t("No Media")}</p>
            )}
          </div>

          {selectedImage && (
            <ImageModal imageUrl={selectedImage} onClose={closeModal} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Attachment;
