import useLoginWithFacebook from "pages/Auth/Singin/hooks/useLoginWithFacebook";
import { useTranslation } from "react-i18next";
import { LoginSocialFacebook } from "reactjs-social-login";

const RegisterWithFacebook = ({ setOpenSignUp }) => {
  const { t } = useTranslation("auth");
  const { facebookLogin, redirectURI, appId } = useLoginWithFacebook(() =>
    setOpenSignUp(false)
  );
  return (
    <LoginSocialFacebook
      appId={appId}
      fieldsProfile={"name,email"}
      redirect_uri={redirectURI}
      onResolve={facebookLogin}
      onReject={(err) => undefined}
    >
      <button className="px-2.5 py-2.5 !w-full text-sm  lg:text-base rounded-full flex border !border-black border-1 flex-row-reverse justify-between items-center ">
        <span className="inline-block bg-[#316FF6]  rounded-full text-white text-2xl font-bold items-center justify-center">
          <div className="w-7 h-7">f</div>
        </span>
      </button>
    </LoginSocialFacebook>
  );
};

export default RegisterWithFacebook;
