import { _ChatApi } from "api/chat/chat";
import { enqueueSnackbar } from "notistack";
import pusherClient from "pusher";
import Pusher from "pusher-js";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { chatStore } from "store/chatStore";
import { v4 as uuidv4 } from "uuid";

export const useConversation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("chat");
  const messagesContainerRef = useRef(null);
  const lastMessageRef = useRef(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [page, setPage] = useState(1);
  const [messages, setMessages] = useState([]);
  const [loadingScroll, setIsLoadingScroll] = useState(false);
  const [newMessage, setNewMessage] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [msgLoading, setMsgLoading] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [lastID, setLastID] = useState();
  const [firstID, setFirstID] = useState();
  const [isGetMore, setIsGetMore] = useState(false);
  const [clearChat, setClearChat] = useState(false);
  const [
    chatID,
    setChatID,
    attachmentRes,
    setAttachmentRes,
    setReceiveMess,
    receiveMess,
  ] = chatStore((state) => [
    state.chatID,
    state.setChatID,
    state.attachmentRes,
    state.setAttachmentRes,
    state.setReceiveMess,
    state.receiveMess,
  ]);

  const [chatActions, setChatActions] = useState({
    block: false,
    clearChat: false,
    reportUser: false,
  });

  const {
    data: chat,
    isLoading,
    refetch,
  } = useQuery(
    ["chat", `id-${chatID}`],
    async () => {
      return await _ChatApi.show({ chatID, page }).then((res) => {
        setLastID(
          res.data.conversations.messages.data[
            res.data.conversations.messages.data.length - 1
          ]?.id
        );
        return res.data?.conversations;
      });
    },
    {
      onSuccess: (data) => {
        setIsLastPage(
          typeof data?.messages.pagination.next_page_url !== "string"
        );
      },
    }
  );
  const username = chat?.user_name;
  const blockAction = chat?.is_block;

  const allMessages = useMemo(() => {
    return [...messages, ...receiveMess].sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );
  }, [messages, receiveMess]);

  // pusher settings
  useEffect(() => {
    Pusher.logToConsole = true;
    pusherClient.connection.bind("connected", () => {});

    pusherClient.connection.bind("disconnected", () => {});

    pusherClient.connection.bind("error", (err) => {
      console.error("Pusher error:", err);
    });
    let channel = pusherClient.channel(`private-conversation.${chatID}`);
    if (!channel) {
      channel = pusherClient.subscribe(`private-conversation.${chatID}`);
    }

    // Bind the event listener for new messages
    channel.unbind("App\\Events\\SendMessage");
    channel.bind("App\\Events\\SendMessage", (data) => {
      if (data?.sender_type !== "user") {
        // Sort received messages based on created_at
        setReceiveMess(data);
        setClearChat(false);
      } else {
        // Handle user messages if needed
      }
    });

    // Cleanup the subscription when the component unmounts
    // Cleanup the subscription when the component unmounts
    return () => {
      channel.unbind("App\\Events\\SendMessage");
      pusherClient.unsubscribe(`private-conversation.${chatID}`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pusherClient, setReceiveMess, chatID]);

  const loadMoreMessages = useCallback(async () => {
    setIsLoadingScroll(true);
    try {
      // Fetch the next page of messages
      const nextPage = page + 1;
      const response = await _ChatApi.loadMoreMessage({
        chatID,
        lastID,
        nextPage,
      });
      const newMessages = response.data?.conversations?.messages?.data ?? [];
      setIsLastPage(
        typeof response.data?.conversations?.messages.pagination
          .next_page_url !== "string"
      );
      setFirstID(response.data.conversations.messages.data[0].id);
      setMessages((prevMessages) => [...prevMessages, ...newMessages]);
      setIsGetMore(true);
      setPage(nextPage);
    } finally {
      setIsLoadingScroll(false);
    }
  }, [page, chatID, lastID]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = messagesContainerRef?.current?.scrollTop;
      if (scrollTop === 0 && !isLoading && !isLastPage) {
        // Reached the top of the container
        loadMoreMessages();
      }
    };

    messagesContainerRef?.current?.addEventListener("scroll", handleScroll);

    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      messagesContainerRef?.current?.removeEventListener(
        "scroll",
        handleScroll
      );
    };
  }, [isLoading, loadMoreMessages, messagesContainerRef, isLastPage]);

  const allMessagesCombined = useMemo(() => {
    if (clearChat) return [];
    return [
      ...(chat?.messages.data?.slice()?.reverse() || []),
      ...allMessages.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      ),
    ];
  }, [allMessages, chat?.messages.data, clearChat]);
  useEffect(() => {
    if (isGetMore && messagesContainerRef.current) {
      messagesContainerRef.current.scrollBy(
        0,
        lastMessageRef.current.offsetTop -
          messagesContainerRef.current.clientHeight / 8
      );
    }
    if (messagesContainerRef.current && !isGetMore)
      messagesContainerRef.current.scrollBy(
        0,
        messagesContainerRef.current.scrollHeight
      );
  }, [allMessagesCombined, isGetMore]);

  useEffect(() => {
    refetch();
    setIsGetMore(false);
    setMessages([]);
  }, [chatID, chat, refetch]);

  const handleRemoveImage = (indexToRemove) => {
    // Create a copy of the selectedImages array
    const updatedImages = [...selectedImages];
    // Remove the image at the specified index
    updatedImages.splice(indexToRemove, 1);
    // Update the state with the new array
    setSelectedImages(updatedImages);
  };
  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };
  const handleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  const handleSendMessage = async (e) => {
    e.preventDefault();
    try {
      const messageText = newMessage;
      // Send the message and images to the server
      if (messageText.trim().length > 0 || selectedImages.length > 0) {
        const messageId = uuidv4();
        setMsgLoading(true);
        const timestamp = new Date().toISOString();
        const messageObject = {
          text: messageText,
          images: selectedImages,
          timestamp: timestamp,
          created_at: timestamp, // Add created_at property
          sent: true,
          sender_type: "user", // Set the sender_type for user messages
          messageId: messageId,
        };
        // Update the messages state
        setIsGetMore(false);
        setMessages((prevMessages) => [...prevMessages, messageObject]);
        setReceiveMess([]);
        // Clear the input field and selected images
        setNewMessage("");
        setSelectedImages([]);
        const formData = new FormData();
        formData.append("content", messageText);
        formData.append("conversation_id", chat?.id);
        formData.append("uuid", messageId);
        // Append each selected image to the FormData
        if (selectedImages.length > 0) {
          selectedImages.forEach((image, index) => {
            formData.append(`media[${index}]`, image);
          });
        }
        // Make the API call to send the message
        await _ChatApi.sendMessage({ messageId, formData }).then((res) => {
          // scrollToBottom();
        });
        // Clear the input field and selected images (optional, depending on your UI requirements)
        setNewMessage("");
        setReceiveMess([]);
        setClearChat(false);
        setSelectedImages([]);
      }
    } catch (error) {
      if (error.response?.data.message) {
        if (Array.isArray(error.response?.data.message)) {
          // If the message is an array, iterate over it
          error.response?.data.message.forEach((errorMessage) => {
            enqueueSnackbar(errorMessage, {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
            });
          });
        } else {
          // If the message is a string, display it directly
          enqueueSnackbar(error.response?.data.message, {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          });
        }
      } else {
        // Handle other cases or display a generic error message
        enqueueSnackbar("An error occurred. Please try again.", {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      }
    } finally {
      setMsgLoading(false);
    }
  };

  const formatDate = () => {
    const parsedDate = new Date();
    const options = {
      hour: "2-digit", // Use 2 digits for hours
      minute: "2-digit", // Use 2 digits for hours
    };

    // Format the date using Intl.DateTimeFormat
    const formattedTime = new Intl.DateTimeFormat("en-US", options).format(
      parsedDate
    );

    // Replace the "/" with "-" and remove the comma

    return formattedTime;
  };
  // to handling images
  const handleImage = (e) => {
    const selectedFiles = e.target.files;
    const selectedImagesArray = Array.from(selectedFiles);
    setSelectedImages(selectedImagesArray);
  };

  return {
    t,
    chat,
    firstID,
    chatID,
    username,
    navigate,
    setChatID,
    isLoading,
    openDrawer,
    formatDate,
    msgLoading,
    openModal,
    closeModal,
    chatActions,
    blockAction,
    newMessage,
    handleImage,
    handleDrawer,
    loadingScroll,
    setOpenDrawer,
    attachmentRes,
    lastMessageRef,
    setChatActions,
    setNewMessage,
    selectedImages,
    setClearChat,
    setAttachmentRes,
    handleSendMessage,
    handleRemoveImage,
    messagesContainerRef,
    allMessagesCombined,
    selectedImage,
  };
};
