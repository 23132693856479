import { create } from "zustand";

export const searchStore = create((set) => ({
  search: null,
  type: "",
  inputText: "",
  openSearchModal: false,
  tab: "services",
  servicesList: [],
  subServicesList: [],
  serviceProvidersList: [],

  servicePage: 1,
  subServicePage: 1,
  serviceProviderPage: 1,

  clearPage: () =>
    set((state) => ({
      ...state,
      servicePage: 1,
      subServicePage: 1,
      serviceProviderPage: 1,
    })),
  clearLists: () =>
    set((state) => ({
      ...state,
      servicesList: [],
      subServicesList: [],
      serviceProvidersList: [],
    })),

  setServicePage: () =>
    set((state) => ({ ...state, servicePage: state.servicePage + 1 })),
  setSubServicePage: () =>
    set((state) => ({ ...state, subServicePage: state.subServicePage + 1 })),
  setServiceProviderPage: () =>
    set((state) => ({
      ...state,
      serviceProviderPage: state.serviceProviderPage + 1,
    })),

  setServicesList: (data) => {
    set((state) => ({
      ...state,
      servicesList: [...state.servicesList, ...data],
    }));
  },
  setSubServicesList: (data) => {
    set((state) => ({
      ...state,
      subServicesList: [...state.subServicesList, ...data],
    }));
  },
  setServiceProvidersList: (data) => {
    set((state) => ({
      ...state,
      serviceProvidersList: [...state.serviceProvidersList, ...data],
    }));
  },
  setType: (type) => {
    set((state) => ({ ...state, type }));
  },
  setInputText: (inputText) => {
    set((state) => ({ ...state, inputText }));
  },
  setTab: (tab) => {
    set((state) => ({ ...state, tab }));
  },
  setSearch: (search) => {
    set((state) => ({ ...state, search }));
  },
  setOpenSearchModal: (openSearchModal) => {
    set((state) => ({ ...state, openSearchModal }));
  },
}));
