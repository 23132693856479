import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { footerStore } from "store/footerStore";
import { Hero } from "./components";
import Benefits from "./components/Benefits";
import Crossin from "./components/Crossin";
import FastPlayer from "./components/FastPlayer";
import OfferMany from "./components/OfferMany";
const Home = () => {
  // to know the location of the page
  const location = useLocation();
  // check Token
  // const access_token = _AuthApi.getToken();
  // navigate for another page
  const navigate = useNavigate();
  // Search params for url location
  const queryParameters = new URLSearchParams(location.search);

  // label for footer to change the value
  const label = footerStore((state) => state.label);
  // ref Forward for sections
  const servicesRef = useRef();
  const applicationRef = useRef();
  const whyMiboRef = useRef();

  // condition for scroll when click on footer and then go bottom for section depending on Ref
  useEffect(() => {
    window.scrollTo(0, 0);
    switch (label) {
      case "Home":
      case "Startseite":
        window?.scrollTo(0, 0);
        break;
      case "Services":
      case "Dienstleistungen":
        servicesRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        break;
      case "Repairs":
      case "Reparaturen":
        applicationRef?.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "Why Mibo":
      case "Features":
      case "Warum Mibo":
      case "Funktionen":
        whyMiboRef?.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  }, [label]);

  // to get token while login with google auth
  if (queryParameters.get("access_token") !== null) {
    localStorage.setItem("access_token", queryParameters.get("access_token"));
    navigate("/");
  }

  return (
    <div className="">
      {/* Hero Section */}

      <Hero />
      {/* Booking Sections */}
      {/* {access_token ? <BookingsHome /> : ""} */}
      {/* Services Section */}
      {/* <Services servicesRef={servicesRef} /> */}
      <FastPlayer />
      <OfferMany ref={servicesRef} />
      <Benefits ref={whyMiboRef} />
      <Crossin ref={applicationRef} />
      {/* <WhyChooseUs />
      <MostAsked />
      <MiboHelping whyMiboRef={whyMiboRef} />
      <Feedback />
      <DownloadApp applicationRef={applicationRef} /> */}
    </div>
  );
};

export default Home;
