import rateIcon from "assets/icons/rateIcon.svg";
import rateIconEmpty from "assets/icons/svg/Star 3.svg";
import heartProviderFull from "assets/icons/svg/heartProvFull.svg";
import heartProvider from "assets/icons/svg/heartProvider.svg";
import DefaultImage from "assets/images/profile/defaultImage.png";
import { useTranslation } from "react-i18next";
const ServicesProvider = ({ data, viewProfile, setServiceProvider }) => {
  const { t } = useTranslation("index");
  return (
    <>
      <div className="w-full h-full ">
        <div className="montserrat font-normal flex w-full lg:flex-wrap gap-10 px-1 justify-start max-lg:overflow-x-auto pb-2">
          {data?.service_providers?.map((item, index) => (
            <div
              className="cursor-pointer hover:scale-105 duration-300 my-4 !rounded-[27.07px] !rounded-b-[23.42px] w-[162px] h-[164px] lg:w-[257.93px] lg:h-[225.98px]"
              key={index}
            >
              <div
                onClick={() => {
                  viewProfile(item.id);
                  setServiceProvider(item.name);
                }}
                className="relative"
              >
                <div className="absolute flex justify-end items-center w-full p-3">
                  <img
                    src={
                      item.favourite === 1 ? heartProviderFull : heartProvider
                    }
                    className="w-[20.67px] h-[17.91px]"
                    alt=""
                  />
                </div>
                <div className="flex flex-col items-center justify-center w-full ">
                  <div className="w-full bg-[#F7F7F7]  !rounded-t-[27.07px]    ">
                    <img
                      src={item.image ? item.image : DefaultImage}
                      alt=""
                      className=" object-cover w-full h-[107px]  !rounded-t-[27.07px] cursor-pointer lg:!w-full lg:!h-[170.36px]  "
                    />
                  </div>
                  <div className="flex justify-between w-full px-2 py-[10px]">
                    <div className="flex items-center gap-1 lg:gap-2">
                      <div>
                        <p className="text-[10px] lg:text-[12px] flex justify-start items-center gap-2">
                          {item.name ?? t("Name")}
                          <span
                            className={`w-[4px] h-[4px] ${
                              item.is_available === 1
                                ? "bg-fourth-color"
                                : "bg-yellow-800"
                            }  rounded-full`}
                          />
                        </p>
                        {item.rate === 0 ? (
                          <img
                            src={rateIconEmpty}
                            alt=""
                            className="w-[15px] h-[15px]"
                          />
                        ) : item.rate === 1 ? (
                          <img
                            src={rateIcon}
                            alt=""
                            className="w-[15px] h-[15px]"
                          />
                        ) : item.rate === 2 ? (
                          <div className="flex">
                            <img
                              src={rateIcon}
                              alt=""
                              className="w-[15px] h-[15px]"
                            />
                            <img
                              src={rateIcon}
                              alt=""
                              className="w-[15px] h-[15px]"
                            />
                          </div>
                        ) : item.rate === 3 ? (
                          <div className="flex">
                            <img
                              src={rateIcon}
                              alt=""
                              className="w-[15px] h-[15px]"
                            />
                            <img
                              src={rateIcon}
                              alt=""
                              className="w-[15px] h-[15px]"
                            />
                            <img
                              src={rateIcon}
                              alt=""
                              className="w-[15px] h-[15px]"
                            />
                          </div>
                        ) : item.rate === 4 ? (
                          <div className="flex">
                            <img
                              src={rateIcon}
                              alt=""
                              className="w-[15px] h-[15px]"
                            />
                            <img
                              src={rateIcon}
                              alt=""
                              className="w-[15px] h-[15px]"
                            />
                            <img
                              src={rateIcon}
                              alt=""
                              className="w-[15px] h-[15px]"
                            />
                            <img
                              src={rateIcon}
                              alt=""
                              className="w-[15px] h-[15px]"
                            />
                          </div>
                        ) : item.rate === 5 ? (
                          <div className="flex">
                            <img
                              src={rateIcon}
                              alt=""
                              className="w-[15px] h-[15px]"
                            />
                            <img
                              src={rateIcon}
                              alt=""
                              className="w-[15px] h-[15px]"
                            />
                            <img
                              src={rateIcon}
                              alt=""
                              className="w-[15px] h-[15px]"
                            />
                            <img
                              src={rateIcon}
                              alt=""
                              className="w-[15px] h-[15px]"
                            />
                            <img
                              src={rateIcon}
                              alt=""
                              className="w-[15px] h-[15px]"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <p className="text-[#D4D4D4] text-[10px] lg:text-[12px] !font-[500] montserrat">
                      {item.price ?? t("price")}$/
                      <br />
                      {t("hours")}
                    </p>
                  </div>
                </div>
                <div
                  className={`text-center flex justify-center items-center text-white w-[162px] h-[31px] lg:w-[257.93px] lg:h-[49.36px] !rounded-b-[23.42px] ${
                    item.is_available === 1
                      ? "bg-primary-color"
                      : "bg-yellow-800"
                  } `}
                >
                  <p className="text-[11px] lg:text-[15.16px] montserrat !font-[400]">
                    {item.is_available === 1
                      ? t("Order now")
                      : t("Not Available")}
                  </p>
                </div>
              </div>

              {/* <div className="flex justify-between lg:w-11/12">
                  <p className="text-sm font-semibold lg:text-xl lg:font-bold">
                    {item.name ?? "Name"}
                  </p>
                  <div className="flex items-start gap-2 lg:items-center ">
                    <img src={rateIcon} alt="" />
                    <p className="text-sm font-semibold lg:text-xl lg:font-bold">
                      {item.rate ?? "Rate"}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between lg:w-11/12">
                  <p className="text-[#919090]">{item.email ?? "Email"}</p>
                </div> */}
            </div>
          ))}
        </div>

        {/* {data?.service_providers.length > 0 && (
          <ReactPaginate
            className="flex justify-center gap-10 my-10 "
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            previousLabel={
              <img
                src={currentPage >= 1 ? ArrowLeftBlack : ArrowLeft}
                alt="Previous"
                className=""
              />
            }
            nextLabel={
              <img
                src={
                  currentPage === totalPages - 1 ? ArrowRight : ArrowRightBlack
                }
                alt="Next"
                className="your-icon-styles-here"
              />
            }
            activeClassName={"active"}
          />
        )} */}
      </div>
    </>
  );
};

export default ServicesProvider;
