import backIcon from "assets/icons/svg/Back.svg";
import arrowIcon from "assets/icons/svg/account/ArrowLeft.svg";
import Loader from "components/styled/Loader";
import { useSettingLogic } from "../../../hooks/useSettingLogic";

const PrivacyAndPolicy = () => {
  const { t, navigate, privacyData, privacyLoading } = useSettingLogic();
  return (
    <div className="flex flex-col items-center justify-center w-full h-full lg:flex-row lg:flex">
      {privacyLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex items-start w-full h-full lg:hidden">
            <div className="flex gap-5 p-5 mt-5 " onClick={() => navigate(-1)}>
              <button>
                <img src={backIcon} alt="" />
              </button>
              <p className="text-xl font-bold">{privacyData?.privacy?.title}</p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center w-full rounded-lg lg:mt-40 lg:p-10 lg:mx-10 lg:bg-third-color ">
            <div className="hidden w-full px-10 lg:flex-row lg:flex gap-14 ">
              <img
                src={arrowIcon}
                alt=""
                onClick={() => navigate(-1)}
                className="cursor-pointer"
              />
              <p className="text-2xl font-bold">
                {privacyData?.privacy?.title}
              </p>
            </div>

            <div
              dangerouslySetInnerHTML={{
                __html: privacyData?.privacy?.message ?? t("Privacy"),
              }}
              className="max-h-[700px]  whitespace-break-spaces  overflow-scroll flex flex-col w-full gap-5 p-10 lg:px-20 lg:gap-10 "
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PrivacyAndPolicy;
