import React from "react";
import { useTranslation } from "react-i18next";
import { searchStore } from "store/searchStore";

const TabButton = ({ tab, resultNumber = 0 }) => {
  const setTab = searchStore((state) => state.setTab);
  const activeTab = searchStore((state) => state.tab);
  const setType = searchStore((state) => state.setType);
  const { t } = useTranslation("index");
  return (
    <button
      onClick={() => {
        setTab(tab);
        setType(tab);
      }}
      type="button"
      className={`px-4 py-2 rounded-xl max-sm:w-full transition-all duration-200 ${
        activeTab !== tab ? "bg-gray-500" : "bg-primary-color"
      }`}
    >
      {`${t(tab.split("_").join(" "))} (${resultNumber})`}
    </button>
  );
};

export default TabButton;
