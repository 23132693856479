import { useNavigate } from "react-router-dom";
import { searchStore } from "store/searchStore";

const ServiceProviderList = ({ setOpenSearchModal }) => {
  const serviceProvidersList = searchStore(
    (state) => state.serviceProvidersList
  );
  const navigate = useNavigate();
  const hanldeProfileShow = (id) => {
    navigate(`/service-provider/profile/${id}`);
  };
  return serviceProvidersList.map((serviceProvider) => (
    <button
      key={serviceProvider.id}
      onClick={() => {
        hanldeProfileShow(serviceProvider.id);
        setOpenSearchModal(false);
      }}
      type="button"
      className="flex items-center w-full gap-10 p-4 my-4 border rounded-lg"
    >
      <img
        loading="lazy"
        src={serviceProvider.image}
        className="w-20 h-20"
        alt={serviceProvider.name}
      />
      <p className="text-xl font-medium text-primary-color line-clamp-1">
        {serviceProvider.name}
      </p>
    </button>
  ));
};

export default ServiceProviderList;
