import google from "assets/icons/google-icon-logo-svg-vector.svg";
import { LoginSocialGoogle } from "reactjs-social-login";
import useLoginWithGoogle from "../hooks/useLoginWithGoogle";
import { useSignInLogic } from "../hooks/useSignInLogic";
import { useTranslation } from "react-i18next";

const LoginWithGoogle = ({ handleClose }) => {
  const { clientId, googleLogin } = useLoginWithGoogle(handleClose);
  const {t}=useTranslation('index')
  return (
    <LoginSocialGoogle
      client_id={clientId}
      scope="openid profile email"
      discoveryDocs="claims_supported"
      onResolve={googleLogin}
      onReject={(err) => undefined}
      className="w-full"
    >
      <div className="flex justify-center items-center gap-2 border rounded-3xl !border-black border-1 !w-full py-[13px]">
        <img src={google} alt="" className="w-6 h-6 cursor-pointer" />
        <div className="text-[13px] font-[400]">{t('Continue with Google')}</div>
      </div>
    </LoginSocialGoogle>
  );
};

export default LoginWithGoogle;
