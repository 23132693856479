import { yupResolver } from "@hookform/resolvers/yup";
import Loader from "components/styled/Loader";
import { _axios } from "interceptor/http-config";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
const ReportUser = ({ setChatActions, username, chatID }) => {
  const schema = yup.object().shape({
    reason: yup.string().required("Reason is required"),
  });
  const { t } = useTranslation("chat");
  const [reportUser, setReportUser] = useState(false);
  const queryClient = useQueryClient();
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const clearToggle = useMutation(
    async (input) => {
      const formData = new FormData();
      const { reason } = input;
      if (reason && reason.length < 10) {
        enqueueSnackbar("Reason must be at least 10 characters", {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
        return;
      }
      formData.append("reason", input.reason);
      return _axios
        .post(`/user/conversation/report/${chatID}`, formData)
        .then((response) =>
          enqueueSnackbar(response.data.message, {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          })
        );
    },
    {
      onMutate: async (id) => {
        await queryClient.cancelMutations("");
        return id;
      },
      onError: (error, variables, context) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const { message } = error.response.data;
          Object.keys(message).forEach((field) => {
            const errorMessages = message[field];
            enqueueSnackbar(errorMessages.join(", "), {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
            });
          });
        }
      },
      onSettled: (id, error, result) => {
        // Handle success or error cases and clear loading state
        if (!error) {
          queryClient.invalidateQueries(["chat"]);
        } else {
          // If there's an error, revert the optimistic update
        }
        setReportUser(false);
        setChatActions(false);
      },
      onSuccess: (response) => {
        setChatActions(false);
      },
    }
  );

  const handleReport = async (id) => {
    setReportUser(true);
    await clearToggle.mutateAsync(id);
  };
  return (
    <>
      {reportUser && <Loader />}

      <div
        onClick={() => setChatActions(false)}
        className="fixed top-0 left-0 z-10 flex items-center justify-center w-screen h-screen bg-black/50"
      ></div>
      <form
        onSubmit={handleSubmit(handleReport)}
        className="absolute top-[25%] lg:p-20 flex flex-col lg:justify-around gap-20 lg:gap-0 justify-center z-50 bg-white h-96  p-1 rounded-lg lg:left-1/3 left-0 mx-10 lg:mx-0 lg:w-1/4"
      >
        <p className="text-2xl text-[#213241] text-center">
          {t("Are you sure you want to Report")} {username} ?
        </p>
        <textarea
          placeholder="Write here"
          name="reason"
          {...register("reason")}
          className="h-40 p-2 mx-4 border border-black rounded-lg focus:outline-none focus:ring-primary-color focus:ring-2 "
        />
        {errors.reason && (
          <span className="mx-4 text-red-500 ">{errors.reason.message}</span>
        )}
        <div className="flex justify-center w-full gap-10 text-white">
          <button
            type="submit"
            className="py-2 px-14 bg-primary-color rounded-xl"
          >
            {t("Yes")}
          </button>
          <button
            onClick={() => setChatActions(false)}
            className="py-2 border px-14 border-primary-color rounded-xl text-primary-color"
          >
            {t("No")}
          </button>
        </div>
      </form>
    </>
  );
};

export default ReportUser;
