import { yupResolver } from "@hookform/resolvers/yup";
import { _AuthApi } from "api/auth";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { settingsStore } from "store/settingsStore";
import * as yup from "yup";
let schema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(6, "The Password must be of six characters")
    .max(20, "The Password must be of 20 characters"),

  new_password_confirmation: yup
    .string()
    .required("Confirm password is required")
    .min(6, "The confirm password must be of six characters")
    .max(20, "The confirm password must be of 20 characters")
    .oneOf([yup.ref("password")], "your password does not match"),
  createdOn: yup.date().default(function () {
    return new Date();
  }),
});
export const usePasswordEditForm = () => {
  const { t } = useTranslation("auth");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { phone, code } = useParams();
  const [new_password, setNewPassword] = useState("");
  const [new_password_confirmation, setConfirmPassword] = useState("");
  const [direction] = settingsStore((state) => [state.direction]);
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    setLoading(true);
    _AuthApi
      .passEdit({
        phone,
        code,
        new_password,
        new_password_confirmation,
      })
      .then((res) => navigate("/", { replace: true }))
      ?.finally(() => setLoading(false))
      ?.catch((error) => {
        const errorResponse = error?.response?.data;
        if (!errorResponse) {
          enqueueSnackbar(
            "An unexpected error occurred. Please try again later",
            {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
            }
          );
        }
        if (typeof errorResponse?.message === "object") {
          Object.values(errorResponse.message).forEach((errorMessages) => {
            errorMessages.forEach((errorMessage) => {
              enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
              });
            });
          });
        }
        if (typeof errorResponse?.message === "string") {
          enqueueSnackbar(errorResponse?.message, {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          });
        }
      });
  };
  const hanldeShowPass = () => {
    setShowPass(!showPass);
  };
  const hanldeShowConfirmPass = () => {
    setShowConfirmPass(!showConfirmPass);
  };
  return {
    t,
    errors,
    loading,
    navigate,
    register,
    onSubmit,
    showPass,
    direction,
    handleSubmit,
    new_password,
    setNewPassword,
    hanldeShowPass,
    showConfirmPass,
    setConfirmPassword,
    hanldeShowConfirmPass,
    new_password_confirmation,
  };
};
