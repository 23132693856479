import { yupResolver } from "@hookform/resolvers/yup";
import { _axios } from "interceptor/http-config";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useValidation } from "../validation/useValidation";

export const useChangePassword = () => {
  const navigate = useNavigate();
  const { schema } = useValidation();
  const { t } = useTranslation("index");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { mutate } = useMutation((data) => createPost(data));
  const { errors } = formState;
  async function createPost(data) {
    setLoading(true);
    await _axios
      .post("/user/change-password", data, setLoading, navigate)
      .then((response) => {
        setLoading(true);
        enqueueSnackbar(response.data.message, {
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      })
      .catch((error) => {
        const errorResponse = error?.response?.data;
        if (errorResponse && errorResponse.message) {
          Object.values(errorResponse.message).forEach((errorMessages) => {
            errorMessages.forEach((errorMessage) => {
              enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
              });
            });
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const onSubmit = async (input) => {
    const formData = new FormData();

    formData.append("current_password", input.current_password);
    formData.append("password", input.password);
    formData.append("password_confirmation", input.password_confirmation);

    // Mutate and reset the form after successful submission
    await mutate(formData);
    if (!formState.isSubmitting) {
      reset();
    }
  };
  return {
    navigate,
    onSubmit,
    loading,
    t,
    register,
    errors,
    handleSubmit,
  };
};
