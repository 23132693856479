import { _AuthApi } from "api/auth";
import heartEmpty from "assets/icons/heartEmpty.svg";
import heartFill from "assets/icons/heartFill.svg";
import DefaultImage from "assets/images/profile/defaultImage.png";
import BookingMap from "components/Map/BookingMap";
import RatingReview from "components/RatingReview/RatingReview";
import Report from "components/Report/Report";
import Loader from "components/styled/Loader";
import { singinStore } from "store/singinStore";
import { useBookingShow } from "../hooks/useBookingShow";
import { useMutation, useQueryClient } from "react-query";
import { _axios } from "interceptor/http-config";
import { useParams } from "react-router-dom";

const BookingShow = () => {
  const {
    paymentLoading,
    openRating,
    setOpenRating,
    openReport,
    setOpenReport,
    openMap,
    setOpenMap,
    cancelLoading,
    loadingFavorite,
    handleFavorite,
    isLoading,
    details,
    lat,
    long,
    handleCancel,
    handlePayment,
    bookingShow,
    navigate,
    t,
    handleAddCoupon,
    addCoupon,
    setAddCoupon,
    couponLoading,
    setCode,
  } = useBookingShow();
  const access_token = _AuthApi.getToken();

  const [openSignIn, setOpenSignIn] = singinStore((state) => [
    state.openSignIn,
    state.setOpenSignIn,
  ]);
  const { id } = useParams();
  const queryClient = useQueryClient();

  const removeCoupon = useMutation({
    mutationFn: async () => {
      await _axios.delete(`/user/coupon/booking/${id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["booking-show", `id-${id}`] });
    },
  });
  return (
    <section className="p-2 lg:px-10">
      {!bookingShow || isLoading || cancelLoading || paymentLoading ? (
        <Loader />
      ) : (
        <>
          {openMap && (
            <BookingMap setOpenMap={setOpenMap} long={long} lat={lat} />
          )}
          {openRating && <RatingReview setOpenRating={setOpenRating} />}

          {openRating && (
            <div
              className="fixed top-0 left-0 z-10 w-screen h-screen bg-black/75"
              onClick={() => setOpenRating(!openRating)}
            ></div>
          )}
          {openReport && <Report setOpenReport={setOpenReport} />}

          {openReport && (
            <div
              className="fixed top-0 left-0 z-10 w-screen h-screen bg-black/75"
              onClick={() => setOpenReport(!openReport)}
            ></div>
          )}

          <div className="!px-10 bg-white w-full lg:flex lg:flex-row flex-col flex lg:justify-between lg:py-4">
            <div className="relative flex flex-col justify-around w-full lg:w-1/2">
              <p className="text-[64px] lg:text-[109.42px] font-[400] Surpass-Holiday text-[#FF2626]">
                {bookingShow?.booking?.service_provider?.name ?? "Mibo"}
              </p>
              <div className="flex gap-2 pt-4">
                <p className="text-[16.88px] !font-[400] montserrat text-[#4C4C4C]">
                  {t('Services:')}
                </p>
                <div className="flex flex-wrap gap-2">
                  <div className="!border-primary-color border items-center justify-center flex rounded-[24.61px] w-[89px] py-3 lg:!py-4 lg:w-[136.28px] text-[14.76px] text-primary-color !h-[26px]  lg:h-[32px]">
                    <p className="Roboto font-[400] lg:font-[700] text-[12px] lg:!text-[14.76px]">
                      {bookingShow?.booking?.sub_service_name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between w-full gap-2 pt-4 my-4 lg:my-0 lg:w-9/12">
                <div className="flex gap-2 ">
                  {access_token ? (
                    <button
                      className="bg-primary-color text-white w-[83.43px] lg:w-[115.43px] h-[39.43px] lg:h-[43.43px] rounded-[7.03px] cursor-pointer hover:scale-105 duration-700 text-[12px] lg:text-[16.48px] montserrat !font-[500] lg:font-[700]"
                      onClick={() => setOpenMap(!openMap)}
                    >
                      {('Location')}
                    </button>
                  ) : (
                    <button
                      onClick={() => setOpenSignIn(!openSignIn)}
                      className="bg-primary-color text-white w-[83.43px] lg:w-[115.43px] h-[39.43px] lg:h-[43.43px] rounded-[7.03px] cursor-pointer hover:scale-105 duration-700 text-[12px] lg:text-[16.48px] montserrat !font-[500] lg:font-[700]"
                    >
                      {t('Order now')}
                    </button>
                  )}
                  <div className="bg-third-color w-[41.23px] h-[39px] lg:w-[45px] lg:h-[43.16px] rounded-[7.64px] flex items-center justify-center ">
                    {!access_token ? (
                      <img
                        src={heartEmpty}
                        alt="heart Empty"
                        onClick={() => setOpenSignIn(!openSignIn)}
                        className="w-[21.77px] h-[19.72px] lg:h-[17.69px] lg:w-[19.54px]"
                      />
                    ) : loadingFavorite ? (
                      <p className="text-[8px]">{t('loading...')}</p>
                    ) : (
                      <img
                        src={
                          bookingShow?.booking?.service_provider?.favourite ===
                          1
                            ? heartFill
                            : heartEmpty
                        }
                        alt=""
                        onClick={() => handleFavorite(bookingShow.id)}
                        className="w-[21.77px] h-[19.72px] lg:h-[17.69px] lg:w-[19.54px]"
                      />
                    )}
                  </div>
                </div>
                <div className="flex items-center w-1/1 justify-center lg:w-11/12 h-[39.43px] lg:h-[43.43px] rounded-[7.03px] duration-700 text-[12px] lg:text-[16.48px] gap-2 max:md:p-3 !bg-[#E5ECF3] montserrat !font-[500] lg:font-[700]">
                  <p className="">{t("Status")}:</p>
                  <p className="text-green-600 ">
                    {bookingShow?.booking?.status}
                  </p>
                </div>
              </div>
            </div>
            <div className="text-black montserrat lg:w-1/2 ">
              <div className="flex items-center justify-center ">
                <div className="">
                  <img
                    className="z-10 object-cover md:object-contain rounded-lg w-48 h-80 md:h-[407.91px] md:w-[407.68px]"
                    src={
                      bookingShow?.booking?.service_provider?.image
                        ? bookingShow?.booking?.service_provider?.image
                        : DefaultImage
                    }
                    alt=""
                  />
                </div>
                <div className={`absolute md:mr-20 lg:mr-10 `}>
                  {/* Work Experiance */}

                  <div
                    className={`absolute right-10 lg:right-20 lg:-top-16 -top-5 px-3 py-7 gap-2 montserrat rounded-[11.1px] justify-center lg:!h-[75px] lg:w-[208.65px] w-[132.95px] h-[46.14px]  drop-shadow-xl flex flex-col    bg-primary-color `}
                  >
                    <div className="flex items-center gap-2 ">
                      <img
                        src={details?.[0].icon}
                        alt=""
                        className="w-[16.22px] h-[18.72px] lg:w-[25.46px] lg:h-[29.38px]"
                      />
                      <p className="text-[9.98px] lg:text-[15.67px] text-white montserrat">
                        {details?.[0].data} years
                      </p>
                    </div>
                    <div>
                      <p className="text-[8.32px] lg:text-[13.06px] text-white font-[400]">
                        {details?.[0].text}
                      </p>
                    </div>
                  </div>
                  {/* Complete Project */}
                  <div
                    className={` absolute lg:-right-72 top-10   gap-2 py-7  montserrat rounded-[11.1px]   !px-[13.37px] justify-center lg:!h-[73.03px]  lg:w-[217.22px] w-[132.95px] h-[46.14px]   drop-shadow-xl flex flex-col    bg-primary-color `}
                  >
                    <div className="flex items-center gap-2 ">
                      <img
                        src={details?.[1].icon}
                        alt=""
                        className="w-[16.22px] h-[18.72px] lg:w-[25.46px] lg:h-[29.38px]"
                      />
                      <p className="text-[9.98px] lg:text-[15.67px] text-white montserrat">
                        +{details?.[1].data}
                      </p>
                    </div>
                    <div>
                      <p className="text-[8.32px] lg:text-[13.06px] text-white font-[400]">
                        {details?.[1].text}
                      </p>
                    </div>
                  </div>
                  {/*USER REVIEW */}
                  <div
                    onClick={() =>
                      navigate(
                        `/review/${bookingShow?.booking?.service_provider.id}`
                      )
                    }
                    className={`cursor-pointer animate-spin-slow absolute right-10 lg:right-28 top-20 px-3 py-7 gap-2 montserrat rounded-[11.1px] justify-center lg:!h-[75px] lg:w-[208.65px] w-[132.95px] h-[46.14px]  drop-shadow-xl flex flex-col    bg-primary-color `}
                  >
                    <div className="flex items-center gap-2 ">
                      <img
                        alt=""
                        src={details?.[2].icon}
                        className="w-[16.22px] h-[18.72px] lg:w-[25.46px] lg:h-[29.38px]"
                      />
                      <p className="text-[9.98px] lg:text-[15.67px] text-white montserrat">
                        {details?.[2].data}
                      </p>
                    </div>
                    <div>
                      <p className="text-[8.32px] lg:text-[13.06px] text-white font-[400]">
                        {details?.[2].text}
                      </p>
                    </div>
                  </div>
                  {/* PER HOUR */}
                  <div
                    className={`absolute -right-40 lg:-right-80 lg:-top-32 -top-20 px-3 py-7 gap-2 montserrat rounded-[11.1px] justify-center lg:!h-[72.41px] lg:w-[141.65px] w-[132.95px] h-[46.14px] drop-shadow-xl flex flex-col bg-primary-color `}
                  >
                    <div className="flex items-center gap-2 ">
                      <img
                        alt=""
                        src={details?.[3].icon}
                        className="w-[16.22px] h-[18.72px] lg:w-[25.46px] lg:h-[29.38px]"
                      />
                      <p className="text-[9.98px] lg:text-[15.67px] text-white montserrat">
                        {details?.[3].data}€
                      </p>
                    </div>
                    <div>
                      <p className="text-[8.32px] lg:text-[13.06px] text-white font-[400]">
                        {details?.[3].text}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-between w-full px-10 mt-5 lg: md:px-10">
            <div className="flex flex-col justify-between w-full gap-8 mt-6 md:mt-1 md:flex-row md:flex">
              <div className="flex flex-col gap-3 md:gap-5 md:w-1/2">
                <div className="">
                  <p className="font-semibold md:text-2xl">
                    {t("The Problem")}:
                  </p>
                  <p className="flex flex-wrap mt-2 text-sm md:mt-5 md:text-base text-justify">
                    {bookingShow?.booking?.job_specification}
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-2 pr-10 md:gap-2 md:grid-cols-3 md:grid">
                  {bookingShow?.booking.gallery.map((item, index) => (
                    <img
                      key={index}
                      src={item}
                      alt=""
                      className="rounded w-1/1 md:w-1/2"
                    />
                  ))}
                </div>
                {(bookingShow?.booking?.status_value === "waiting" ||
                  bookingShow?.booking?.status_value === "accept" ||
                  bookingShow?.booking?.status_value === "pending") && (
                  <button
                    onClick={() => handleCancel(bookingShow?.booking?.id)}
                    className="w-32 p-2 text-white md:w-60 bg-primary-color rounded-3xl"
                  >
                    {t("Cancel")}
                  </button>
                )}
              </div>
              {bookingShow?.booking?.status_value === "completed" ||
              bookingShow?.booking?.status_value === "done" ? (
                <div className="flex flex-col gap-5 md:w-1/2">
                  {addCoupon ? (
                    <form
                      onSubmit={handleAddCoupon}
                      className="flex flex-col gap-3"
                    >
                      <input
                        placeholder="Enter Your Coupon here"
                        onChange={(e) => setCode(e.target.value)}
                        required
                        className="md:w-1/2 border !border-[#FF2626] p-3 rounded-lg focus:ring-2 focus:outline-none focus:ring-[#F6B940] duration-700"
                      />
                      <button
                        type="submit"
                        className="w-32 p-2 text-center text-white md:w-60 bg-primary-color rounded-3xl"
                      >
                        {couponLoading ? <p>Loading...</p> : <p>Done</p>}
                      </button>
                    </form>
                  ) : (
                    <div className="flex justify-between md:w-1/2">
                      <div className="flex flex-col gap-4">
                        {bookingShow?.booking?.invoice_value ? (
                          <p className="text-xl font-semibold">
                            {bookingShow?.booking?.coupon_value
                              ? "Sub Total"
                              : "Total"}
                          </p>
                        ) : null}
                        {bookingShow?.booking?.coupon_value ? (
                          <p className="text-xl font-semibold text-[#FF2626]">
                            Coupon
                          </p>
                        ) : null}
                        {bookingShow?.booking?.price_after_discount ? (
                          <p className="text-xl font-semibold">Total</p>
                        ) : null}
                      </div>
                      <div className="flex flex-col gap-4">
                        {bookingShow?.booking?.invoice_value ? (
                          <p className="text-xl font-semibold">
                            {bookingShow?.booking?.invoice_value}€
                          </p>
                        ) : null}
                        {bookingShow?.booking?.coupon_value ? (
                          <p className="text-xl font-semibold">
                            {bookingShow?.booking?.coupon_value}%
                          </p>
                        ) : null}

                        {bookingShow?.booking?.price_after_discount ? (
                          <p className="text-xl font-semibold">
                            {bookingShow?.booking?.price_after_discount}€
                          </p>
                        ) : null}
                      </div>
                    </div>
                  )}
                  {bookingShow?.booking?.status_value === "done" &&
                  !("price_after_discount" in bookingShow?.booking) ? (
                    <div
                      className="flex justify-start py-2 md:w-1/2 text-[#FF2626] cursor-pointer hover:font-semibold duration-1000"
                      onClick={() => setAddCoupon(!addCoupon)}
                    >
                      <button>
                        {addCoupon ? t("Close") : t("+ Add Coupon")}
                      </button>
                    </div>
                  ) : null}
                  {bookingShow?.booking?.status_value === "done" &&
                  "price_after_discount" in bookingShow?.booking ? (
                    <button
                      onClick={() => removeCoupon.mutate()}
                      className="text-start border !border-red-500 bg-red-500/30 capitalize p-2 rounded-lg max-w-[400px] flex justify-between items-center"
                    >
                      {removeCoupon.isLoading ? (
                        <span className="font-bold">loading...</span>
                      ) : (
                        <>
                          <span className="font-bold">remove coupon</span>
                          <span className="font-bold rounded-full !text-red-500 text-3xl flex items-baseline">
                            +
                          </span>
                        </>
                      )}
                    </button>
                  ) : null}
                  {bookingShow?.booking?.status_value === "done" && (
                    <button
                      className="w-32 p-2 text-center text-white md:w-60 bg-primary-color rounded-3xl"
                      onClick={() => handlePayment(bookingShow?.booking?.id)}
                    >
                      {t("Pay")}
                    </button>
                  )}
                  {bookingShow?.booking?.status_value === "completed" && (
                    <div className="flex gap-2 md:gap-5">
                      <button
                        className="w-32 p-2 text-[10px] text-white md:text-lg md:w-60 bg-primary-color rounded-3xl"
                        onClick={() => setOpenRating(!openRating)}
                      >
                        {t("Add Reviews & Rating")}
                      </button>
                      <button
                        className="w-32 p-2 text-[10px] text-white md:text-lg md:w-60 bg-primary-color rounded-3xl"
                        onClick={() => setOpenReport(!openReport)}
                      >
                        {t("Report")}
                      </button>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default BookingShow;
