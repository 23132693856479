import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";

const FastPlayer = () => {
  const { t } = useTranslation("index");
  return (
    <div className="flex flex-col mx-4 mb-6 overflow-hidden lg:mx-0 lg:mb-[70px] max-[330px]:mt-[150px] max-md:mt-[100px]">
      <div className="flex justify-end w-full text-white lg:w-full lg:justify-center lg:flex lg:ml-64 lg:my-4">
        <p className="text-[11px] rounded-t-full rounded-r-full w-[250px] lg:!w-[450px] lg:text-xl px-3 lg:!pl-12 lg:py-10 py-3 rounded-l-0 bg-primary-color ">
          {t("home.Hi can you help me, I need my house to")} <br />
          {t("home.be clean")}. 🧼🧹
        </p>
      </div>
      <div className="flex justify-center">
        <Marquee
          style={{ transform: "rotate(-3.86deg)" }}
          className="py-4 text-2xl !px-0 font-bold tracking-widest text-black uppercase montserrat lg:text-9xl"
          speed={100}
        >
          {t("home.Quick replies")}{" "}
          <span className="flex items-center justify-center px-1 pb-2 lg:!pb-16">
            {" "}
            .{" "}
          </span>{" "}
          {t("home.Needed answers")}{" "}
          <span className="flex items-center justify-center px-1 pb-2 lg:!pb-16">
            .{" "}
          </span>
        </Marquee>
      </div>
      <div className="flex justify-start w-full pb-3 text-white lg:justify-center lg:flex lg:my-4 -rotate-[3.86deg] lg:pr-96 ">
        <p className="flex items-center justify-center px-4 py-3 text-sm lg:rounded-t-full rounded-l-[25px] rounded-tr-[25px] lg:rounded-l-full lg:text-xl lg:py-10 lg:!px-14 rounded-r-0 bg-fifth-color">
          {t("home.Of course! We're here to help. Can you")}
          <br /> {t("home.provide some details about your home and")}
          <br /> {t("home.what you need cleaned?")}
        </p>
      </div>
    </div>
  );
};

export default FastPlayer;
