import { useQuery } from "react-query";
import { _AccountApi } from "api/account/account";

export const usePrivacy = () => {
  const { data, isLoading } = useQuery(
    ["privacy"],
    () => _AccountApi.privacy().then((res) => res),
    {
      keepPreviousData: true,
    }
  );
  return {
    data,
    isLoading,
  };
};
