import backIcon from "assets/icons/svg/Back.svg";
import arrowIcon from "assets/icons/svg/account/ArrowLeft.svg";
import hiddenPassIcon from "assets/images/hiddenPass.png";
import showCurrentPassIcon from "assets/images/showPass.png";
import Loader from "components/styled/Loader";
import { useState } from "react";
import { useChangePassword } from "../hooks/useChangePassword";
const ChangePassword = () => {
  const { navigate, onSubmit, loading, t, register, errors, handleSubmit } =
    useChangePassword();
  const [showCurrentPass, setShowCurrentPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const hanldeShowCurrentPass = () => {
    setShowCurrentPass(!showCurrentPass);
  };
  const hanldeShowNewPass = () => {
    setShowNewPass(!showNewPass);
  };
  const hanldeShowConfirmPass = () => {
    setShowConfirmPass(!showConfirmPass);
  };
  return (
    <div className="flex flex-col items-center justify-center w-full h-full lg:flex-row lg:flex">
      <div className="flex items-start w-full h-full lg:hidden">
        <div className="flex gap-5 p-5 mt-5 " onClick={() => navigate(-1)}>
          <button>
            <img src={backIcon} alt="" />
          </button>
          <p className="text-xl font-bold">{t("Change Password")}</p>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center w-full rounded-lg lg:mt-32 lg:p-10 lg:mx-10 lg:bg-third-color ">
        <div className="hidden w-full px-10 lg:flex-row lg:flex gap-14 ">
          <img
            src={arrowIcon}
            alt=""
            onClick={() => navigate(-1)}
            className="cursor-pointer"
          />
          <p className="text-2xl font-bold">{t("Change Password")}</p>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col w-full gap-5 p-10 lg:px-20 lg:gap-10 "
          >
            <div className="relative flex flex-col gap-2">
              <label className="font-semibold">{t("Current Password")}</label>
              <div className="relative ">
                <input
                  placeholder={t("Enter Your Current Password")}
                  type={showCurrentPass ? "text" : "password"}
                  name="current_password"
                  {...register("current_password")}
                  className="w-full p-3 border border-primary-color rounded-3xl lg:p-5"
                />
                <img
                  src={showCurrentPass ? showCurrentPassIcon : hiddenPassIcon}
                  className={`absolute w-5 text-black cursor-pointer right-2 top-1/2 transform -translate-y-1/2`}
                  alt=""
                  onClick={hanldeShowCurrentPass}
                />
              </div>
              {errors.current_password && (
                <span className="text-red-500">
                  {errors.current_password.message}
                </span>
              )}
            </div>

            <div className="relative flex flex-col gap-2">
              <label className="font-semibold">{t("New Password")}</label>

              <div className="relative ">
                <input
                  placeholder={t("Enter Your New Password")}
                  type={showNewPass ? "text" : "password"}
                  name="password"
                  {...register("password")}
                  className="w-full p-3 border border-primary-color rounded-3xl lg:p-5"
                />
                <img
                  src={showNewPass ? showCurrentPassIcon : hiddenPassIcon}
                  className={`absolute w-5 text-black cursor-pointer right-2 top-1/2 transform -translate-y-1/2`}
                  alt=""
                  onClick={hanldeShowNewPass}
                />
              </div>
              {errors.password && (
                <span className="text-red-500">{errors.password.message}</span>
              )}
            </div>

            <div className="relative flex flex-col gap-2">
              <label className="font-semibold">{t("Confirm Password")}</label>

              <div className="relative ">
                <input
                  placeholder={t("Confirm Password")}
                  type={showConfirmPass ? "text" : "password"}
                  id="password_confirmation"
                  name="password_confirmation"
                  {...register("password_confirmation")}
                  className="w-full p-3 border border-primary-color rounded-3xl lg:p-5"
                />
                <img
                  src={showConfirmPass ? showCurrentPassIcon : hiddenPassIcon}
                  className={`absolute w-5 text-black cursor-pointer right-2 top-1/2 transform -translate-y-1/2`}
                  alt=""
                  onClick={hanldeShowConfirmPass}
                />
              </div>
              {errors.password_confirmation && (
                <span className="text-red-500">
                  {errors.password_confirmation.message}
                </span>
              )}
            </div>

            <div className="flex flex-col items-center justify-center my-10 lg:w-1/1">
              <button
                type="submit"
                className="relative w-8/12 h-12 text-white lg:w-full rounded-3xl bg-primary-color"
              >
                <p>{t('update')}</p>
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ChangePassword;
