import { _axios } from "interceptor/http-config";
import { facebookModal } from "store/facebookModal";

const useLoginWithFacebook = (handleClose) => {
  const redirectURI = "https://mibo.r-link.io";
  const appId = "827913909134592";
  const [setOpenModal, setAccessToken, setName] = facebookModal((state) => [
    state.setOpenModal,
    state.setAccessToken,
    state.setName,
  ]);

  const facebookLogin = async ({ data: response }) => {
    const { data } = await _axios.get(
      `/user/login/facebook?access_token=${
        response.accessToken
      }&device_type=web&device_token=${
        localStorage.getItem("firebaseToke") || ""
      }`
    );
    handleClose();
    setAccessToken(data.profile.access_token);
    setName(data.profile.name);
    if (data.profile.phone === null) setOpenModal(true);
    else {
      localStorage.setItem("access_token", data.profile.access_token);
    }
  };
  return { facebookLogin, redirectURI, appId };
};

export default useLoginWithFacebook;
