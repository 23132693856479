import * as yup from "yup";

export const useValidation = () => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const schema = yup.object().shape({
    phone: yup
      .string()
      .required("Phone is required")
      .test("is-number", "Phone must be a number", (value) => !isNaN(value))
      .matches(phoneRegExp, "Phone number is not valid"),
    password: yup
      .string()
      .required("Password is required")
      .min(6, "The Password must be of six characters")
      .max(20, "The Password must be of 20 characters"),
  });
  return {
    schema,
  };
};
