import logo from "assets/icons/logo.png";
import hiddenPassIcon from "assets/images/hiddenPass.png";
import showPassIcon from "assets/images/showPass.png";
import Loader from "components/styled/Loader";
import { usePasswordEditForm } from "./hooks/usePasswordEditForm";
import { Swiper, SwiperSlide } from "swiper/react";
import img from "assets/images/Auth/sss.jpg";
import img2 from "assets/images/Auth/ImgRegister.png";
import img3 from "assets/images/Auth/OO.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
const PasswordEditForm = () => {
  const {
    t,
    errors,
    loading,
    navigate,
    register,
    onSubmit,
    showPass,
    direction,
    handleSubmit,
    new_password,
    setNewPassword,
    hanldeShowPass,
    showConfirmPass,
    setConfirmPassword,
    hanldeShowConfirmPass,
    new_password_confirmation,
  } = usePasswordEditForm();
  return (
    <div>
      {loading && <Loader />}

      <div className="fixed top-0 z-50 flex items-center justify-center w-full h-full">
        <div
          onClick={() => navigate(-1)}
          className="fixed top-0 z-40 !cursor-pointer w-screen h-screen bg-black/75"
        ></div>
        <div className="z-40 flex items-center justify-center w-full md:w-auto">
          <div className="flex justify-center w-full">
            {/* <div
              className={`rounded-l-xl text-white hidden md:flex flex-col gap-14 bg-primary-color  md:w-1/2 p-10`}
            >
              <div>
                <p>{t("Edit Password")}</p>
              </div>
              <div>
                <p>{t("Welcome to Mibo")}</p>
              </div>
              <div>
                <p>
                  {t(
                    "Welcome back to Mibo! To enhance the security of your account, we need you to set a new password"
                  )}
                </p>
              </div>
              <div>
                <p className="text-5xl font-bold">MIBO</p>
              </div>
            </div> */}
            <div
              className={`text-white hidden md:flex overflow-hidden md:w-[290px] rounded-l-2xl relative z-10 `}
            >
              <Swiper
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay, Pagination]}
                className="overflow-hidden mySwiper"
              >
                <SwiperSlide>
                  <img
                    src={img}
                    className="w-full object-cover max-h-[600px] md:max-h-[700px]"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img2}
                    className="w-full object-cover max-h-[600px] md:max-h-[700px]"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img3}
                    className="w-full object-cover max-h-[600px] md:max-h-[700px]"
                    alt=""
                  />
                </SwiperSlide>
              </Swiper>
            </div>
            <div
              className={`md:rounded-r-xl min-h-[600px] w-full max-h-[600px] md:max-h-[700px] relative z-10
         flex flex-col rounded-2xl md:rounded-none overflow-auto scrollbar-hide bg-white md:w-[575px] p-3 md:p-5 max-md:m-3 md:m-0`}
            >
              <div className="flex justify-end w-full ">
                <p
                  className="p-2 font-bold text-[18px] cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  X
                </p>
              </div>

              {/* <div className="flex flex-col items-center justify-center md:hidden">
                <img src={logo} alt="mibo" className="w-1/2" />
              </div> */}
              <div className="flex flex-col items-center justify-start w-full h-full">
                <div className="flex flex-col items-center justify-center">
                  <p className="text-[24px] mb-3 font-bold">Mibo</p>
                </div>
                <div className="text-center">
                  <p>
                    {t(
                      "Welcome back to Mibo! To enhance the security of your account, we need you to set a new password"
                    )}
                  </p>
                </div>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="flex flex-col gap-[17px] 2xl:gap-[20px] w-full max-h-[500px] mt-4 md:max-h-max  md:px-10"
                >
                  <div className="flex flex-col !gap-2">
                    <label className="text-[#666666] relative text-[13.5px]">
                      {t("Password")}
                    </label>
                    <div className="relative">
                      <input
                        type={showPass ? "text" : "password"}
                        sx={{ width: "100%" }}
                        placeholder={t("Enter Password")}
                        {...register("password", { validate: true })}
                        id="password"
                        value={new_password}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="w-full p-2.5 max-md:text-[12px] rounded-xl  md:p-3 border border-primary-color md:rounded-lg"
                      />
                      {errors.password && (
                        <span
                          className="field_level_error"
                          style={{ color: "red" }}
                        >
                          {errors.password.message}
                        </span>
                      )}
                      <img
                        src={showPass ? showPassIcon : hiddenPassIcon}
                        className={`absolute w-5 text-black cursor-pointer ${
                          direction === "ltr" ? "right-2" : "left-2"
                        } top-1/2 transform -translate-y-1/2`}
                        alt=""
                        onClick={hanldeShowPass}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col !gap-2">
                    <label className="text-[#666666] relative text-[13.5px]">
                      {t("Confirm Password")}
                    </label>
                    <div className="relative">
                      <input
                        type={showConfirmPass ? "text" : "password"}
                        sx={{ width: "100%" }}
                        placeholder={t("Confirm Password")}
                        {...register("new_password_confirmation", {
                          validate: true,
                        })}
                        id="new_password_confirmation"
                        value={new_password_confirmation}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="w-full p-2.5 max-md:text-[12px] rounded-xl  md:p-3 border border-primary-color md:rounded-lg"
                      />
                      {errors.new_password_confirmation && (
                        <span
                          className="field_level_error"
                          style={{ color: "red" }}
                        >
                          {errors.new_password_confirmation.message}
                        </span>
                      )}
                      <img
                        src={showConfirmPass ? showPassIcon : hiddenPassIcon}
                        className={`absolute w-5 text-black cursor-pointer ${
                          direction === "ltr" ? "right-2" : "left-2"
                        } top-1/2 transform -translate-y-1/2`}
                        alt=""
                        onClick={hanldeShowConfirmPass}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col items-center justify-center w-full">
                    <button
                      type="submit"
                      className="relative w-full h-12 text-white rounded-3xl bg-primary-color"
                      disabled={loading}
                    >
                      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                        {t("Submit")}
                      </div>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordEditForm;
