import { yupResolver } from "@hookform/resolvers/yup";
import { useProfile } from "hooks/profile/useProfile";
import { _axios } from "interceptor/http-config";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

const schema = yup.object().shape({
  comment: yup.string().required("comment is required"),
});
const useReport = (setOpenReport) => {
  const params = useParams();
  const { t } = useTranslation("index");
  const { data, isLoading } = useProfile();
  const formOptions = { resolver: yupResolver(schema) };
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const handleClose = () => {
    setOpenReport(false);
  };

  const onSubmit = async (input) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("comment", input.comment);

    await _axios
      .post(`/user/provider/report/${params.id}`, formData)
      .then((res) => Swal.fire(res?.data.message))
      .catch((error) => {
        if (typeof error.response?.data.message === "string") {
          Swal.fire(error.response?.data.message);
        } else if (typeof error.response?.data.message === "object") {
          Swal.fire(
            `${Object.values(error.response?.data.message)
              .flat()
              .map((errorMessage) => `<p>${errorMessage}</p>`)}`
          );
        } else {
          Swal.fire("Check Your Connection");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    t,
    onSubmit,
    handleClose,
    register,
    handleSubmit,
    errors,
    loading,
    data,
    isLoading,
  };
};

export default useReport;
