// ImageDataContext.js
import React, { createContext, useState } from 'react';

export const ImageDataContext = createContext();

export const ImageDataProvider = ({ children }) => {
    const [imagesData, setImagesData] = useState([]);
    if (imagesData.length > 4) {
        setImagesData([])
    }

    return (
        <ImageDataContext.Provider value={{ imagesData, setImagesData }}>
            {children}
        </ImageDataContext.Provider>
    );
};
