import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { settingsStore } from "store/settingsStore";
import { singinStore } from "store/singinStore";

export const useNavBarLogic = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("navbar");
  const [openSignUp, setOpenSignUp] = useState(false);
  const [navBg, setNavBg] = useState(false);
  const [openAppleForm, setOpenAppleForm] = useState(false);
  const [isHovered, setIsHovered] = useState({
    chat: false,
    favorite: false,
    account: false,
    notification: false,
  });
  const [direction, setDirection] = settingsStore((state) => [
    state.direction,
    state.setDirection,
  ]);
  const [openSignIn, setOpenSignIn] = singinStore((state) => [
    state.openSignIn,
    state.setOpenSignIn,
  ]);

  const hanldeOpenSignIn = () => {
    setOpenSignIn(!openSignIn);
  };
  const hanldeOpenSignUp = () => {
    setOpenSignUp(!openSignUp);
  };
  const toggleLang = () => {
    setDirection(direction === "ltr" ? "rtl" : "ltr");
    i18n.changeLanguage(direction === "ltr" ? "ger" : "en");
  };

  const changeNavBg = () => {
    window.scrollY >= 10 ? setNavBg(true) : setNavBg(false);
  };
  useEffect(() => {
    window?.addEventListener("scroll", changeNavBg);
    return () => {
      window.removeEventListener("scroll", changeNavBg);
    };
  }, []);

  return {
    t,
    toggleLang,
    navigate,
    direction,
    isHovered,
    setIsHovered,
    location,
    navBg,
    hanldeOpenSignUp,
    hanldeOpenSignIn,
    openSignUp,
    setOpenSignUp,
    openSignIn,
    setOpenSignIn,
    openAppleForm,
    setOpenAppleForm,
  };
};
