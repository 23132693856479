import { _axios } from "interceptor/http-config";
import { enqueueSnackbar } from "notistack";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";

export const _HomeApi = {
  services: async () => {
    const lang = localStorage.getItem("i18nextLng");
    let language = "";
    if (!lang) language = "en";
    else if (["en", "ger"].includes(lang)) language = lang;
    else language = "en";
    return _axios
      .get(`/user/services?language=${language}`, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data)
      .catch((err) =>
        enqueueSnackbar(err.resposne.data.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        })
      );
  },
  mostAsked: async (params) => {
    return _axios
      .get(`/user/sub-service/top`, {
        params,
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data)
      .catch((err) =>
        enqueueSnackbar(err.resposne.data.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        })
      );
  },
  bestApp: async (params) => {
    return _axios
      .get(`/user/service-providers/top`, {
        params,
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data)
      .catch((err) =>
        enqueueSnackbar(err.resposne.data.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        })
      );
  },
};
