import { yupResolver } from "@hookform/resolvers/yup";
import { _AuthApi } from "api/auth";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { settingsStore } from "store/settingsStore";
import { singinStore } from "store/singinStore";
import swal from "sweetalert";
import * as yup from "yup";
export const useOTP = () => {
  const { t } = useTranslation("auth");
  const navigate = useNavigate();
  const schema = yup.object().shape({});
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [direction] = settingsStore((state) => [state.direction]);

  const { phone } = useParams();
  const { handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const [setOpenSignIn] = singinStore((state) => [state.setOpenSignIn]);
  const verifyOtp = async (data) => {
    setLoading(true);
    try {
      await _AuthApi.verifyEmail({ phone, code: data.code });
      swal("Thanks.", "success");
      navigate("/");
      setOpenSignIn(true);
    } catch (error) {
      const errorResponse = error?.response?.data;
      if (!errorResponse) {
        enqueueSnackbar(
          "An unexpected error occurred. Please try again later",
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          }
        );
      }
      if (typeof errorResponse?.message === "object") {
        Object.values(errorResponse.message).forEach((errorMessages) => {
          errorMessages.forEach((errorMessage) => {
            enqueueSnackbar(errorMessage, {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
            });
          });
        });
      }
      if (typeof errorResponse?.message === "string") {
        enqueueSnackbar(errorResponse?.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (index, value) => {
    setValue(`code${index}`, value);
  };
  const onSubmit = (data) => {
    const code = [1, 2, 3, 4].map((index) => data[`code${index}`]).join("");
    verifyOtp({ code });
  };
  const code1Ref = useRef(null);
  const code2Ref = useRef(null);
  const code3Ref = useRef(null);
  const code4Ref = useRef(null);
  return {
    t,
    direction,
    navigate,
    phone,
    onSubmit,
    handleSubmit,
    loading,
    code1Ref,
    code2Ref,
    code3Ref,
    code4Ref,
    handleChange,
  };
};
