import Bookings from "pages/Account/components/Booking/pages/Bookings";
import FAQ from "pages/Account/components/FAQ/FAQ";
import GeTSupport from "pages/Account/components/GetSupport/pages/GeTSupport";
import Profile from "pages/Account/components/profile/pages/Profile";
import ChangePassword from "pages/Account/components/setting/components/ChangePassword/pages/ChangePassword";
import Cookies from "pages/Account/components/setting/components/Cookies/pages/Cookies";
import PrivacyAndPolicy from "pages/Account/components/setting/components/PrivacyAndPolicy/pages/PrivacyAndPolicy";
import TermsAndCondition from "pages/Account/components/setting/components/TermsAndCondition/pages/TermsAndCondition";
import Setting from "pages/Account/components/setting/pages/Setting";
import { Route, Routes } from "react-router-dom";

const ResponsiveRouting = () => {
  return (
    <Routes className="block md:hidden">
      {/* Account Responsive Route */}
      <Route path="profile" element={<Profile />} />
      <Route path="bookings" element={<Bookings />} />
      <Route path="get-support" element={<GeTSupport />} />
      <Route path="FAQ" element={<FAQ />} />
      <Route path="settings" element={<Setting />} />
      <Route path="settings/change-password" element={<ChangePassword />} />
      <Route path="settings/privacy-policy" element={<PrivacyAndPolicy />} />
      <Route path="settings/cookies" element={<Cookies />} />
      <Route path="settings/terms-conditions" element={<TermsAndCondition />} />
      {/* Account Responsive Route */}
    </Routes>
  );
};

export default ResponsiveRouting;
