import ResponsiveRouting from "ResponsiveRouting";
import Map from "components/Map/Map";
import Layout from "layout/Layout";
import ShouldBeLogged from "middlewares/ShouldBeLogged";
import NotFound from "pages/404/NotFound";
import Account from "pages/Account/Account";
import BookingShow from "pages/Account/components/Booking/pages/BookingShow";
import Bookings from "pages/Account/components/Booking/pages/Bookings";
import FAQ from "pages/Account/components/FAQ/FAQ";
import GeTSupport from "pages/Account/components/GetSupport/pages/GeTSupport";
import Profile from "pages/Account/components/profile/pages/Profile";
import ChangePassword from "pages/Account/components/setting/components/ChangePassword/pages/ChangePassword";
import Cookies from "pages/Account/components/setting/components/Cookies/pages/Cookies";
import PrivacyAndPolicy from "pages/Account/components/setting/components/PrivacyAndPolicy/pages/PrivacyAndPolicy";
import TermsAndCondition from "pages/Account/components/setting/components/TermsAndCondition/pages/TermsAndCondition";
import Setting from "pages/Account/components/setting/pages/Setting";
import SideBarLayout from "pages/Account/layout/SideBarLayout";
import PasswordEditForm from "pages/Auth/forgetPassword/PasswordEditForm";
import OtpCode from "pages/Auth/Register/OtpCode";
import ResetPassword from "pages/Auth/forgetPassword/ResetPassword";
import VerificationCodeForm from "pages/Auth/forgetPassword/VerificationCodeForm";
import ChatLayout from "pages/Chat/layout/ChatLayout";
import ChatBlock from "pages/Chat/pages/ChatBlock";
import Messages from "pages/Chat/pages/Messages";
import Favorite from "pages/Favorite/pages/Favorite";
import Home from "pages/Home/Home";
import Notification from "pages/Notification/pages/Notification";
import Reviews from "pages/Reviews/Reviews";
import ServicesLayout from "pages/SubServices/layout/ServicesLayout";
import Order from "pages/SubServices/pages/Order";
import ServicesProviderProfile from "pages/SubServices/pages/ServicesProviderProfile";
import ServicesProviderShow from "pages/SubServices/pages/ServicesProviderShow";
import SubServices from "pages/SubServices/pages/SubServices";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import "./App.css";
const AppRouting = () => {
  return (
    <div>
      <Routes>
        <Route
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route path="/" element={<Home />} />

          {/* Account Responsive Route */}
          <Route
            path="/mobile/*"
            element={
              <ShouldBeLogged>
                <ResponsiveRouting />
              </ShouldBeLogged>
            }
          />
          {/* Account Responsive Route */}
          <Route
            element={
              <ShouldBeLogged>
                <SideBarLayout>
                  <Outlet />
                </SideBarLayout>
              </ShouldBeLogged>
            }
          >
            <Route path="/account" element={<Account />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/bookings" element={<Bookings />} />
            <Route path="/get-support" element={<GeTSupport />} />
            <Route path="/faq" element={<FAQ />} />
            <Route
              path="/settings"
              element={
                <div className="hidden lg:block">
                  <Setting />
                </div>
              }
            />
            <Route
              path="settings/change-password"
              element={
                <div className="hidden lg:block">
                  <ChangePassword />
                </div>
              }
            />
            <Route
              path="settings/change-password"
              element={
                <div className="hidden lg:block">
                  <ChangePassword />
                </div>
              }
            />
            <Route
              path="settings/privacy-policy"
              element={
                <div className="hidden lg:block">
                  <PrivacyAndPolicy />
                </div>
              }
            />
            <Route
              path="settings/terms-conditions"
              element={
                <div className="hidden lg:block">
                  <TermsAndCondition />
                </div>
              }
            />
            <Route
              path="settings/cookies"
              element={
                <div className="hidden lg:block">
                  <Cookies />
                </div>
              }
            />
          </Route>

          <Route
            path="/favorite"
            element={
              <ShouldBeLogged>
                <Favorite />
              </ShouldBeLogged>
            }
          />
          <Route
            path="/notification"
            element={
              <ShouldBeLogged>
                <Notification />
              </ShouldBeLogged>
            }
          />
          {/* Services */}
          <Route
            element={
              <ServicesLayout>
                <Outlet />
              </ServicesLayout>
            }
          >
            <Route path="/services/view/:id" element={<SubServices />} />
            {/* <Route
              path="/service-provider/profile/:subServiceId/:providerId"
              element={<ServicesProviderShow />}
            /> */}
            {/* <Route
              path="/service-provider/profile/:id"
              element={<ServicesProviderProfile />}
            /> */}
            <Route path="/review/:id" element={<Reviews />} />

            <Route
              path="/service-provider/profile/:subServiceId/:providerId/order/:id"
              element={
                <ShouldBeLogged>
                  <Order />
                </ShouldBeLogged>
              }
            />
          </Route>
          <Route
            path="/service-provider/profile/:subServiceId/:providerId"
            element={<ServicesProviderShow />}
          />
          <Route
            path="/service-provider/profile/:id"
            element={<ServicesProviderProfile />}
          />
          <Route path="/booking/:id" element={<BookingShow />} />

          {/* Services */}
          {/* Auth */}
          <Route path="/otp/:phone" element={<OtpCode />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/reset-password/check-code/:phone"
            element={<VerificationCodeForm />}
          />
          <Route
            path="/reset-password/edit-password/:phone/:code"
            element={<PasswordEditForm />}
          />
          <Route path="/" element={<Navigate to="/reset-password" />} />
          {/* <Route path="/add-payment-method" element={<AddPayment />} /> */}

          <Route path="*" element={<NotFound />} />
          {/* Map */}
          <Route path="/map" element={<Map />} />

          {/* Map */}
          {/* Chat */}
          <Route
            element={
              <ShouldBeLogged>
                <ChatLayout>
                  <Outlet />
                </ChatLayout>
              </ShouldBeLogged>
            }
          >
            <Route path="/chat/message" element={<Messages />} />
            <Route path="/chat/block" element={<ChatBlock />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
};

export default AppRouting;
