import CARPENTERBg from "assets/images/services/CARPENTER (2).png";
import CLEANERBg from "assets/images/services/CLEANER.png";
import FARMERBg from "assets/images/services/FARMER.png";
import PAINTERBg from "assets/images/services/PAINTER.png";
import electricBg from "assets/images/services/electric2.png";
import servicesBg from "assets/images/services/servicesBg.png";
import { useServices } from "hooks/home/useServices";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { servicesStore } from "store/servicesStore";
const Services = () => {
  const { data, isLoading, isSuccess, isError, error } = useServices();
  const [setServicesState, setServicesName, setServicesIcon, servicesName] =
    servicesStore((state) => [
      state.setServicesState,
      state.setServicesName,
      state.setServicesIcon,
      state.servicesName,
    ]);
  const [active, setActive] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("index");
  const handleItemClick = (item) => {
    setServicesState(item.id);
    setActive(item.id); // Set the active ID for the clicked item
    if (window.location.pathname !== "/") {
      navigate(`/services/view/${item.id}`);
    }
    setServicesName(item.name);
    setServicesIcon(item.image);
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      const item = data?.services?.find(
        (service) => service.id?.toString() === id
      );
      setServicesName(item?.name);
      setServicesIcon(item?.image);
      setActive(item?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isError, error, isSuccess, isLoading]);

  return (
    // <div className="bg-red-400">
    //   {isLoading && <Loader />}
    //   <div className="flex items-center max-w-[700px] sm:max-w-full px-5 lg:px-0 overflow-scroll gap-10 w-full h-20 border border-l-0 border-r-0 border-black lg:gap-20 lg:pl-10 services-scroll">
    //     {data?.services?.map((item) => (
    //       <div key={item.id} className="">
    //         <p
    //           className={`text-xs cursor-pointer  md:text-lg lg:text-xl  hover:text-primary-color duration-1000 ${
    //             active === item.id ? "text-primary-color font-semibold" : ""
    //           }`}
    //           onClick={() => handleItemClick(item)}
    //         >
    //           {item.name}
    //         </p>
    //       </div>
    //     ))}
    //   </div>
    // </div>
    <div
      className="p-3 lg:!pt-20 lg:!pl-20 w-full h-[226px] lg:h-[351px] lg:flex lg:flex-row flex-col flex text-white lg:justify-between bg-contain bg-center"
      style={{
        backgroundImage: `url('${
          servicesName === "Plumber"
            ? servicesBg
            : servicesName === "Electrical"
            ? electricBg
            : servicesName === "Carpenter"
            ? CARPENTERBg
            : servicesName === "Painter"
            ? PAINTERBg
            : servicesName === "Cleaner"
            ? CLEANERBg
            : servicesName === "Gardner"
            ? FARMERBg
            : servicesBg
        }')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="relative w-full">
        <p className="text-[36px] w-fit font-bold montserrat flex flex-col justify-between">
          <span>{t("Services")}</span>
          {!isLoading && isSuccess ? (
            <span className="text-sm ms-auto Surpass-Holiday flex">
              {data?.services
                ?.map((service) => service.id)
                ?.indexOf(
                  data?.services?.find((service) => {
                    return service.id?.toString() === id;
                  })?.id
                ) + 1}
              <svg
                width="15"
                height="18"
                viewBox="0 0 15 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.619141 16.6763L13.6421 1.34766"
                  stroke="white"
                  strokeLinecap="round"
                />
              </svg>
              {data.pagination.total}
            </span>
          ) : null}
        </p>
      </div>
      <div className="flex flex-col w-full montserrat">
        <p className=" text-[16px] lg:text-[36px] montserrat font-bold ">
          {servicesName}
        </p>
        <p className="text-xs lg:text-lg Roboto flex lg:!font-thin font-[400] opacity-80 lg:w-3/4">
          {t("servicesDesc")}
        </p>
      </div>
    </div>
  );
};

export default Services;
