import FavoriteError from "components/error/FavoriteError";
import Loader from "components/styled/Loader";
import useFavorite from "../hooks/useFavorite";
import FavoriteCard from "../components/FavoriteCard";
import { Fragment } from "react";

const Favorite = () => {
  const { t, data, isLoading, loadingFavorite } = useFavorite();

  return (
    <section className="px-2 md:px-4 mt-0 lg:px-0">
      <div className="flex lg:pb-6 pl-3 lg:pl-10 gap-2 lg:!gap-5 items-center relative text-xl border-b text-[14.42px] font-semibold lg:text-[36px] text-[#302F2A]">
        <p className="font-[700] montserrat">{t("Favorites")} </p>
        <p className="text-white relative  bg-white items-center justify-center flex rounded-[3.09px] lg:rounded-[8.96px] w-[16.48px] h-[16.48px] lg:!w-[47.79px] lg:!h-[47.79px] text-[10.3px] lg:text-[29.87px] ">
          {/* {count} */}
        </p>
        <p className="absolute h-[2.06px] lg:h-[5.97px] bg-[#302F2A] top-7 lg:top-[68px] w-[123.6px] lg:w-[358.42px]"></p>
      </div>
      {data?.favourites.length === 0 ? (
        <FavoriteError />
      ) : (
        <div className="relative flex h-full">
          <div className="w-1/2 h-[600px] rounded-3xl rounded-r-none">
            <div className="px-2 lg:p-1 absolute w-full max-h-[600px] overflow-scroll flex flex-col gap-0 lg:gap-10 max-lg:mt-2 notifications-scroll">
              {isLoading || loadingFavorite ? (
                <Loader />
              ) : (
                data?.favourites?.map((item) => (
                  <Fragment key={item.id}>
                    <FavoriteCard favorite={item} />
                    <p className="!h-0.5 !bg-third-color !w-full text-white ">
                      .
                    </p>
                  </Fragment>
                ))
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Favorite;
