import { useNotifications } from "hooks/notification/useNotifications";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { chatStore } from "store/chatStore";

const useNotification = () => {
  const { t } = useTranslation('index');
  const setChatID = chatStore((state) => state.setChatID);
  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
  } = useNotifications();
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const count = data?.pages?.slice(0, 1).map((item) => item?.count);
  const formatDate = (dateTimeString) => {
    // Parse the input date string
    const parsedDate = new Date(dateTimeString);
    // Format options for the desired output
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit", // Use 2 digits for hours
      minute: "2-digit", // Use 2 digits for minutes
      hour12: true, // Use 12-hour format
    };
    // Format the date using Intl.DateTimeFormat
    const formattedTime = new Intl.DateTimeFormat("en-US", options).format(
      parsedDate
    );
    // Replace the "/" with "-" and remove the comma
    const formattedTimeModified = formattedTime
      .replace(/\//g, "-")
      .replace(",", "");
    return formattedTimeModified;
  };
  const handelNavigateButton = (item) => {
    if (item.relatable_type !== "booking") {
      setChatID(item.relatable_id);
      navigate("/chat/message");
    } else {
      navigate(`/booking/${item.relatable_id}`);
    }
  };

  useEffect(() => {
    const current = containerRef.current;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = current;

      if (shouldFetchMore(scrollTop, scrollHeight, clientHeight)) {
        fetchNextPage();
      }
    };
    const shouldFetchMore = (scrollTop, scrollHeight, clientHeight) => {
      return (
        scrollTop + clientHeight >= scrollHeight - 10 && // Add a threshold for fetching, adjust as needed
        !isFetchingNextPage &&
        !isLoading &&
        !isFetching &&
        hasNextPage
      );
    };
    if (current) {
      current?.addEventListener("scroll", handleScroll);
      return () => {
        current.removeEventListener("scroll", handleScroll);
      };
    }
  }, [fetchNextPage, isFetchingNextPage, isLoading, isFetching, hasNextPage]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return {
    t,
    data,
    isLoading,
    formatDate,
    containerRef,
    isFetchingNextPage,
    handelNavigateButton,
    count,
  };
};

export default useNotification;
