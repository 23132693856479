import { useReviews } from "hooks/reviews/useReviews";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ReviewCard from "./components/ReviewCard";
import { useTranslation } from "react-i18next";

const Reviews = () => {
  const { id } = useParams();
  const { data } = useReviews({ id });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation("index");
  return (
    <section className="flex flex-col gap-10 px-10 md:px-20">
      <h2 className="text-xl font-semibold">{t("Rating And Reviews")}</h2>
      <div className="max-h-[600px] overflow-scroll">
        {data?.reviews?.length > 0 ? (
          data?.reviews?.map((item, index) => (
            <ReviewCard item={item} key={index} />
          ))
        ) : (
          <p className="text-xl font-semibold text-center">
            {t("No Reviews Yet")}
          </p>
        )}
      </div>
    </section>
  );
};

export default Reviews;
