import { yupResolver } from "@hookform/resolvers/yup";
import { _AuthApi } from "api/auth";
import { _axios } from "interceptor/http-config";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLocationStore } from "store/useLocationStore";
import Swal from "sweetalert2";
import * as yup from "yup";
const schema = yup.object().shape({
  description: yup.string().required("description is required"),
  date: yup
    .string()
    .required("Date is required")
    .test("date", "Date must be today or later", (date) => {
      return new Date(date).getTime() - new Date().setHours(0, 0, 0, 0) >= 0;
    }),
  // photo: yup.mixed().test("File", "Image Is Required", (value) => {
  //   return value?.length > 0;
  // }),
});
export const useOrder = () => {
  const [locationID, setLocationID] = useLocationStore((state) => [
    state.locationID,
    state.setLocationID,
  ]);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation("index");
  const [openMap, setOpenMap] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const allQueryParameters = {};
  const { subServiceId, providerId } = useParams();
  const queryParameters = new URLSearchParams(location.search);
  // Get all query parameters as an array
  for (const [key, value] of queryParameters.entries()) {
    allQueryParameters[key] = value;
  }

  const formatDate = (dateTimeString) => {
    // Parse the input date string
    const parsedDate = new Date(dateTimeString);

    // Get individual date components
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const day = String(parsedDate.getDate()).padStart(2, "0");
    const hours = String(parsedDate.getHours()).padStart(2, "0");
    const minutes = String(parsedDate.getMinutes()).padStart(2, "0");

    // Construct the formatted date string
    const formattedTimeModified = `${month}-${day}-${year} ${hours}:${minutes}`;

    return formattedTimeModified;
  };
  const handleFileSelect = (e) => {
    const selectedFiles = e.target.files;
    const selectedPhotosArray = Array.from(selectedFiles);
    setSelectedPhotos(selectedPhotosArray);
  };

  const formOptions = { resolver: yupResolver(schema) };
  const { handleSubmit, formState, control, register } = useForm(formOptions);
  const { errors } = formState;
  const hanldeSubmit = async (input) => {
    setLoading(true);
    const formData = new FormData();
    selectedPhotos.forEach((photo, index) => {
      formData.append(`images[${index}]`, photo);
    });
    formData.append(`description`, input.description);
    formData.append(`date`, formatDate(input.date));
    if (locationID) formData.append(`location_id`, locationID);

    await _axios
      .post(
        `/user/book-service-provider/${subServiceId}/${providerId}`,
        formData,
        {
          headers: {
            ...HttpRequestInterceptor(),
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        setLoading(false);
        Swal.fire(`${response?.data.message}`);
        navigate(-2);
      })
      .catch((error) => {
        if (typeof error.response?.data.message === "string") {
          // Handle the case where the message is a string
          Swal.fire(error.response?.data.message);
        } else if (typeof error.response?.data.message === "object") {
          // Handle the case where the message is an object (validation errors)
          Swal.fire(
            `${Object.values(error.response?.data.message)
              .flat()
              .map((errorMessage) => `<p>${errorMessage}</p>`)}`
          );
        } else {
          Swal.fire("Check Your Connection");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [openLocation, setOpenLocation] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [name, setName] = useState("");
  const [locationData, setLocationData] = useState();

  const queryClient = useQueryClient();
  const access_token = _AuthApi.getToken();
  useQuery({
    queryKey: ["user_location"],
    queryFn: async () => {
      if (!access_token) return null;
      return _axios
        .get("/user/location")
        .then((res) => {
          if (res.data) {
            setLocationData(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });
  const handleSelect = (e) => {
    e.preventDefault();

    if (!selectedLocation) {
      // Display error message if selectedLocation is empty
      Swal.fire("Error", "Location is required", "error");
      return; // Exit function if selectedLocation is empty
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("lat", selectedLocation.lat);
    formData.append("long", selectedLocation.lng);
    setLoading1(true);

    _axios
      .post("/user/location", formData)
      .then(() => setLoading1(true))
      .then(() => {
        queryClient.invalidateQueries(["user_location"]);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const { message } = error.response.data;
          Object.keys(message).forEach((field) => {
            const errorMessages = message[field];
            Swal.fire(`${field}: ${errorMessages.join(", ")}`);
          });
        } else {
          Swal.fire(`Network connection...`);
        }
      })
      .finally(() => {
        setLoading1(false);
        setOpenMap(false);
        setOpenLocation(false);
      });
  };
  return {
    t,
    errors,
    control,
    openMap,
    loading,
    register,
    navigate,
    setOpenMap,
    hanldeSubmit,
    handleSubmit,
    selectedPhotos,
    handleFileSelect,
    selectedLocation,
    setSelectedLocation,
    formState,
    openLocation,
    setOpenLocation,
    handleSelect,
    locationID,
    setLocationID,
    openForm,
    setOpenForm,
    loading1,
    name,
    setName,
    locationData,
    setLocationData,
  };
};
