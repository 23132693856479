import { useOTP } from "./hooks/useOTP";
const OtpCode = () => {
  const {
    t,
    direction,
    navigate,
    phone,
    onSubmit,
    handleSubmit,
    loading,
    code1Ref,
    code2Ref,
    code3Ref,
    code4Ref,
    handleChange,
  } = useOTP();

  return (
    <>
      <div className="fixed top-0 w-screen h-screen bg-black/75"></div>
      <div className="fixed top-0 z-50 flex items-center justify-center w-full h-full">
        <div className="flex items-center justify-center ">
          <div className="flex justify-center ">
            <div
              className={`${
                direction === "ltr" ? "rounded-l-xl" : "rounded-r-xl"
              } text-white hidden md:flex flex-col gap-14 bg-primary-color  md:w-1/3 p-10`}
            >
              <div>
                <p>{t("Verification")}</p>
              </div>
              <div>
                <p>{t("Welcome to Mibo")}</p>
              </div>
              <div>
                <p>
                  {t(
                    "Please enter the code we sent it here, to complete your account and verify it."
                  )}
                </p>
              </div>
              <div>
                <p className="text-5xl font-bold">MIBO</p>
              </div>
            </div>
            <div
              className={`${
                direction === "ltr"
                  ? "rounded-xl md:rounded-none md:rounded-r-xl"
                  : "rounded-xl md:rounded-none md:rounded-l-xl"
              }  flex flex-col  gap-10 md:gap-0 bg-white md:w-1/3 p-5 m-7 md:m-0`}
            >
              <div className="flex justify-end md:w-full">
                <p className="cursor-pointer " onClick={() => navigate("/")}>
                  X
                </p>
              </div>
              <div className="flex justify-center md:hidden">
                <p className="text-2xl font-bold ">LoGO</p>
              </div>
              <div className="flex justify-center w-full text-center">
                <p>
                  {t("Enter the 4-digit code sent to you at")} {phone}
                </p>
              </div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col gap-20 md:mt-10 "
              >
                <div className="flex flex-col gap-5 ">
                  <label>{t("OTP")}</label>
                  <div className="flex justify-between gap-2">
                    {[1, 2, 3, 4].map((index) => (
                      <input
                        key={index}
                        placeholder={t("-")}
                        type="text"
                        name={`code${index}`}
                        maxLength={1}
                        required
                        pattern="[0-9]"
                        onChange={(e) => {
                          handleChange(index, e.target.value);
                          // Move focus to the next input field
                          if (e.target.value !== "" && index < 4) {
                            switch (index) {
                              case 1:
                                code2Ref.current.focus();
                                break;
                              case 2:
                                code3Ref.current.focus();
                                break;
                              case 3:
                                code4Ref.current.focus();
                                break;
                              default:
                                break;
                            }
                          }
                        }}
                        ref={(ref) => {
                          // Assign the ref to the appropriate codeRef based on the index
                          switch (index) {
                            case 1:
                              code1Ref.current = ref;
                              break;
                            case 2:
                              code2Ref.current = ref;
                              break;
                            case 3:
                              code3Ref.current = ref;
                              break;
                            case 4:
                              code4Ref.current = ref;
                              break;
                            default:
                              break;
                          }
                        }}
                        className="flex w-1/5 text-center border rounded-full md:w-1/6 h-14 placeholder:text-center border-primary-color"
                      />
                    ))}
                  </div>
                </div>

                <div className="flex flex-col items-center justify-center w-full">
                  {!loading ? (
                    <button
                      type="submit"
                      className="relative w-full h-12 text-white rounded-3xl bg-primary-color"
                      disabled={loading}
                    >
                      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                        {t("Submit")}
                      </div>
                    </button>
                  ) : (
                    <button
                      className="relative w-full h-12 text-white rounded-3xl bg-primary-color"
                      disabled={loading}
                    >
                      <div className="flex items-center justify-center w-full text-center ">
                        <div className="loader"> </div>
                      </div>
                    </button>
                  )}
                  <div className="flex gap-10 py-5 text-center md:px-2 md:py-10">
                    <p>{t("Didn’t receive code?")}</p>
                    <p className="text-primary-color">{t("Resend")}</p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpCode;
