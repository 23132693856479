import { _AuthApi } from "api/auth";
import heartEmpty from "assets/icons/heartEmpty.svg";
import heartFill from "assets/icons/heartFill.svg";
import arrow from "assets/icons/svg/Arrow 2.svg";
import Background from "assets/images/profile/ProviderBg.png";
import Loader from "components/styled/Loader";
import { useRef, useState } from "react";
import { singinStore } from "store/singinStore";
import ProviderHeroSection from "../components/order/ProviderHeroSection";
import { useServiceProvicerProfile } from "../hooks/useServiceProvicerProfile";
import Order from "./Order";
import { useTranslation } from "react-i18next";
const ServicesProviderProfileProfile = () => {
  const {
    details,
    handleFavorite,
    loadingFavorite,
    hanldeSubServices,
    subServicesIDLoading,
    servicesProviderProfile,
    servicesProviderProfileLoading,
  } = useServiceProvicerProfile();

  const orderRef = useRef();
  const [openPricedetails, setOpenPricedetails] = useState(
    Array(servicesProviderProfile?.service_provider?.sub_services.length).fill(
      false
    )
  );
  const acces_token = _AuthApi.getToken();
  const handleButtonClick = (index) => {
    const newOpenPricedetails = [...openPricedetails];
    newOpenPricedetails[index] = !newOpenPricedetails[index];
    setOpenPricedetails(newOpenPricedetails);
  };
  const [openSignIn, setOpenSignIn] = singinStore((state) => [
    state.openSignIn,
    state.setOpenSignIn,
  ]);
  const { t } = useTranslation("index");
  return (
    <div className="p-2 lg:px-10">
      {!servicesProviderProfile ||
      servicesProviderProfileLoading ||
      subServicesIDLoading ? (
        <Loader />
      ) : (
        <>
          <div className="!px-4  bg-white lg:!pl-20 w-full h-[px] lg:h-[408px] lg:flex lg:flex-row flex-col flex   lg:justify-between    lg:py-4">
            <div className="relative flex flex-col justify-center w-full mt-20 lg:mt-40 lg:w-1/2">
              <p className="text-[64px] lg:text-[109.42px] -top-20 font-[400] Surpass-Holiday text-[#FF2626] absolute lg:-top-44 lg:-left-4">
                {servicesProviderProfile?.service_provider.name}
              </p>
              <div className="flex gap-2 pt-4">
                <p className="text-[16.88px] !font-[400] montserrat text-[#4C4C4C]">
                  {t('Services')}:
                </p>
                <div className="flex flex-wrap gap-2">
                  {servicesProviderProfile?.service_provider?.services?.map(
                    (item, index) => (
                      <div
                        key={index}
                        className="!border-primary-color border items-center justify-center flex rounded-[24.61px] w-[89px] py-3 lg:!py-4 lg:w-[136.28px] text-[14.76px] text-primary-color !h-[26px]  lg:h-[32px]"
                      >
                        <p className="Roboto font-[400] lg:font-[700] text-[12px] lg:!text-[14.76px]">
                          {item.name}
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="flex gap-2 pt-4">
                {acces_token ? (
                  <button
                    className="bg-primary-color text-white w-[83.43px] lg:w-[115.43px] h-[39.43px] lg:h-[43.43px] rounded-[7.03px] cursor-pointer hover:scale-105 duration-700 text-[12px] lg:text-[16.48px] montserrat !font-[500] lg:font-[700]"
                    onClick={() =>
                      orderRef.current.scrollIntoView({
                        behavior: "smooth",
                      })
                    }
                  >
                    {t('Order now')}
                  </button>
                ) : (
                  <button
                    onClick={() => setOpenSignIn(!openSignIn)}
                    className="bg-primary-color text-white w-[83.43px] lg:w-[115.43px] h-[39.43px] lg:h-[43.43px] rounded-[7.03px] cursor-pointer hover:scale-105 duration-700 text-[12px] lg:text-[16.48px] montserrat !font-[500] lg:font-[700]"
                  >
                    {t('Order now')}
                  </button>
                )}
                <div className="bg-third-color w-[41.23px] h-[39px] lg:w-[45px] lg:h-[43.16px] rounded-[7.64px] flex items-center justify-center ">
                  {!acces_token ? (
                    <img
                      src={heartEmpty}
                      alt=""
                      onClick={() => setOpenSignIn(!openSignIn)}
                      className="w-[21.77px] h-[19.72px]   lg:h-[17.69px] lg:w-[19.54px]"
                    />
                  ) : loadingFavorite ? (
                    <p className="text-[8px]">{t('Loading...')}</p>
                  ) : (
                    <img
                      src={
                        servicesProviderProfile.service_provider.favourite === 1
                          ? heartFill
                          : heartEmpty
                      }
                      alt=""
                      onClick={() =>
                        handleFavorite(
                          servicesProviderProfile?.service_provider.id
                        )
                      }
                      className="w-[21.77px] h-[19.72px] lg:h-[17.69px] lg:w-[19.54px]"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="text-black montserrat lg:w-1/2 ">
              <ProviderHeroSection
                details={details}
                Background={Background}
                servicesProviderProfile={servicesProviderProfile}
              />
            </div>
          </div>
          <div className="flex flex-col gap-4 px-2 lg:mt-10 lg:flex-row lg:flex">
            <div className="mt-6 lg:mt-0 lg:!w-1/2 w-full overflow-scroll remove-scroll">
              <div className="grid grid-cols-1 md:grid-cols-1 lg:grid lg:!px-[64px] gap-2 max-lg:mx-auto w-full max-lg:px-4 lg:w-10/12">
                {servicesProviderProfile?.service_provider?.sub_services
                  .sort((a, b) => a.id - b.id)
                  .map((item, index) => (
                    <div
                      className="duration-300 rounded-md lg:p-2 hover:scale-105 hover:bg-third-color flex p-3 flex-row gap-3 border-b !border-third-color"
                      key={index}
                    >
                      <div className="flex w-[50px] h-[50px] bg-third-color rounded-full p-2">
                        <img
                          src={item.image}
                          alt=""
                          className="object-contain"
                        />
                      </div>
                      <div className="flex flex-col w-full">
                        <button
                          onClick={() => {
                            handleButtonClick(index);
                            hanldeSubServices(item.id);
                          }}
                          className="flex flex-row items-center w-full gap-4 duration-300 rounded-md hover:scale-105 lg:p-2 "
                        >
                          <p
                            className={`${
                              openPricedetails[index]
                                ? "text-primary-color !font-[700]"
                                : "text-black"
                            } text-sm Roboto lg:text-[14px] !font-[400] Roboto hover:!text-primary-color duration-150`}
                          >
                            {item.name}
                          </p>
                          <img
                            src={arrow}
                            alt=""
                            className={`w-[10.77px] ${
                              openPricedetails[index] ? "" : "rotate-[42deg]"
                            }`}
                          />
                        </button>
                        {openPricedetails[index] && (
                          <p className="font-[400]  text-[12px] lg:p-2 text-[#B5B5B5]">
                            {item.price}$ {item.price_type}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div ref={orderRef} className="flex flex-col w-1/2">
              <div className="">
                <p className="montserrat text-[16px] font-[500] lg:text-[24px]">
                  {t('Ready to order')}
                </p>
                <p className="text-[#333333] mt-2 text-[12px] font-[500] lg:text-[18px] lg:!font-[700]">
                  {t('What is the problem')}
                </p>
                <Order disabledButton={true} />
              </div>
            </div>
          </div>
          {/* <div className="flex items-end justify-end w-full">
            <img
              src={
                servicesProviderProfile.service_provider.favourite === 1
                  ? heartFill
                  : heartEmpty
              }
              alt=""
              onClick={() =>
                handleFavorite(servicesProviderProfile?.service_provider.id)
              }
              className="w-5 h-5 lg:h-10 lg:w-10"
            />
          </div> */}

          {/* <div className="flex flex-col justify-between w-full px-10 mt-20 lg: md:px-10 2xl:px-40 ">
            <div className="flex flex-col justify-between md:flex-row md:flex ">
              <div className="flex items-center justify-between w-full ">
                <div className="flex items-center justify-between w-full ">
                  <p className="w-1/2 text-xs capitalize md:w-full md:text-2xl">
                    {servicesProviderProfile?.service_provider.name}
                  </p>
                  <p className="block text-xs font-semibold capitalize md:text-lg md:hidden text-primary-color">
                    {
                      servicesProviderProfile?.service_provider
                        ?.selected_sub_service?.name
                    }
                  </p>
                </div>
                <div className="items-center justify-between hidden md:flex md:w-full">
                  <p>{t("Other services")}</p>
                  <p className="hidden font-semibold capitalize text-primary-color md:block">
                    {
                      servicesProviderProfile?.service_provider
                        ?.selected_sub_service?.name
                    }
                  </p>
                </div>
              </div>

              <div className="flex items-start justify-between mt-5 text-base text-left gap-14 md:hidden">
                <div className="flex items-center gap-2 text-center ">
                  <div className="">
                    <img src={LocationIcon} alt="" className="w-5 h-5" />
                  </div>
                  <div className="">
                    <p className="">Location</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-between w-full ">
              <div className="items-start justify-center hidden text-xl text-left md:flex-col md:flex gap-14 ">
                <div className="flex items-center gap-5 mt-10 text-center">
                  <div>
                    <img src={LocationIcon} alt="" />
                  </div>
                  <div className="">
                    <p className="">{t("Location")}</p>
                  </div>
                </div>
              </div>
              <div className="flex justify-between gap-10 2xl:w-1/2">
                <div className="grid w-full grid-cols-2 gap-10 mt-10">
                  {itemsToShow.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => hanldeSubServices(item.id)}
                      className="flex flex-col gap-5 p-3 text-sm bg-white rounded-lg cursor-pointer lg:text-lg lg:p-10 drop-shadow-xl text-primary-color hover:scale-110"
                    >
                      <p className="capitalize">{item.name}</p>
                      <p className="font-semibold capitalize">
                        {item.price}$/{item.price_type}
                      </p>
                    </div>
                  ))}
                  {currentIndex + 3 < servicesProviderProfileData.length && (
                    <div className="flex items-center justify-center w-full gap-2 ">
                      <p className="text-2xl text-gray-600">{t("More")}</p>
                      <img
                        onClick={showNextItems}
                        src={ArrowRightBlack}
                        className="w-5 h-5 mt-1 cursor-pointer lg:h-5 lg:w-5 hover:scale-110"
                        alt="Show more"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
};

export default ServicesProviderProfileProfile;
