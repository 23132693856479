import React from "react";
import DefaultImage from "assets/images/profile/defaultImage.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProviderHeroSection = ({ servicesProviderProfile, details }) => {
  const navigate = useNavigate();
  const {t}=useTranslation('index')
  return (
    <div className="flex items-center justify-center ">
      <div className="">
        <img
          className="z-10 object-cover md:object-contain rounded-lg w-48 h-80 md:h-[407.91px] md:w-[407.68px]    "
          src={
            servicesProviderProfile?.service_provider.image
              ? servicesProviderProfile?.service_provider.image
              : DefaultImage
          }
          alt=""
        />
      </div>
      <div className={`absolute md:mr-20 lg:mr-10 `}>
        {/* Work Experiance */}

        <div
          className={` absolute right-10 lg:right-20  lg:-top-16 -top-5 px-3   py-7 gap-2  montserrat rounded-[11.1px]   justify-center  lg:!h-[75px] lg:w-[208.65px] w-[132.95px] h-[46.14px]  drop-shadow-xl flex flex-col    bg-primary-color `}
        >
          <div className="flex items-center gap-2 ">
            <img
              src={details?.[0].icon}
              alt=""
              className="w-[16.22px] h-[18.72px] lg:w-[25.46px] lg:h-[29.38px]"
            />
            <p className="text-[9.98px] lg:text-[15.67px] text-white montserrat">
              {details?.[0].data} {t('years')}
            </p>
          </div>
          <div>
            <p className="text-[8.32px] lg:text-[13.06px] text-white font-[400]">
              {details?.[0].text}
            </p>
          </div>
        </div>
        {/* Complete Project */}
        <div
          className={` absolute lg:-right-72 top-10   gap-2 py-7  montserrat rounded-[11.1px]   !px-[13.37px] justify-center lg:!h-[73.03px]  lg:w-[217.22px] w-[132.95px] h-[46.14px]   drop-shadow-xl flex flex-col    bg-primary-color `}
        >
          <div className="flex items-center gap-2 ">
            <img
              alt=""
              src={details?.[1].icon}
              className="w-[16.22px] h-[18.72px] lg:w-[25.46px] lg:h-[29.38px]"
            />
            <p className="text-[9.98px] lg:text-[15.67px] text-white montserrat">
              +{details?.[1].data}
            </p>
          </div>
          <div>
            <p className="text-[8.32px] lg:text-[13.06px] text-white font-[400]">
              {details?.[1].text}
            </p>
          </div>
        </div>
        {/*USER REVIEW */}
        <div
          onClick={() =>
            navigate(`/review/${servicesProviderProfile?.service_provider?.id}`)
          }
          className={`cursor-pointer animate-spin-slow absolute right-10 lg:right-28 top-20 px-3 py-7   gap-2  montserrat rounded-[11.1px]  justify-center lg:!h-[75px] lg:w-[208.65px] w-[132.95px] h-[46.14px]  drop-shadow-xl flex flex-col    bg-primary-color `}
        >
          <div className="flex items-center gap-2 ">
            <img
              alt=""
              src={details?.[2].icon}
              className="w-[16.22px] h-[18.72px] lg:w-[25.46px] lg:h-[29.38px]"
            />
            <p className="text-[9.98px] lg:text-[15.67px] text-white montserrat">
              +{details?.[2].data}
            </p>
          </div>
          <div>
            <p className="text-[8.32px] lg:text-[13.06px] text-white font-[400]">
              {details?.[2].text}
            </p>
          </div>
        </div>
        {/* PER HOUR */}
        <div
          className={` absolute -right-40 lg:-right-80 lg:-top-32 -top-20 px-3 py-7   gap-2  montserrat rounded-[11.1px]   justify-center  lg:!h-[72.41px] lg:w-[141.65px] w-[132.95px] h-[46.14px]  drop-shadow-xl flex flex-col    bg-primary-color `}
        >
          <div className="flex items-center gap-2 ">
            <img
              alt=""
              src={details?.[3].icon}
              className="w-[16.22px] h-[18.72px] lg:w-[25.46px] lg:h-[29.38px]"
            />
            <p className="text-[9.98px] lg:text-[15.67px] text-white montserrat">
              {details?.[3].data}€
            </p>
          </div>
          <div>
            <p className="text-[8.32px] lg:text-[13.06px] text-white font-[400]">
              {details?.[3].text}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderHeroSection;
