import backIcon from "assets/icons/svg/Back.svg";
import TrackingMap from "components/Map/TrackingMap";
import Loader from "components/styled/Loader";
import BookingList from "../components/BookingList";
import BookingSwitch from "../components/BookingSwitch";
import { useBookingLogic } from "../hooks/useBookingLogic";

const Bookings = () => {
  const {
    t,
    changeBg,
    navigate,
    changeStatus,
    bookingLoading,
    currentBookingList,
    fetchCurrentBookingNextPage,
    hasCurrentBookingNextPage,
    isCurrentBookingFetchingNextPage,
    isCurrentBookingLoading,
    isCurrentBookingFetching,
    lastBookingList,
    open,
    setOpen,
    handleButtonClick,
    fetchLastBookingNextPage,
    hasLastBookingNextPage,
    isLastBookingFetchingNextPage,
    isLastBookingLoading,
    isLastBookingFetching,
  } = useBookingLogic();

  return (
    <>
      <div className="flex items-start h-full lg:hidden">
        <div className="flex gap-4 p-5 mt-5" onClick={() => navigate(-1)}>
          <button>
            <img src={backIcon} alt="" />
          </button>
          <p className="text-xl font-bold">{t("Bookings")}</p>
        </div>
      </div>
      <div className="flex flex-col mt-4 items-center justify-center gap-10 md:px-10 md:mt-0">
        {/* Button Current and Past */}
        <BookingSwitch
          changeBg={changeBg}
          handleButtonClick={handleButtonClick}
        />
        {/* Button Current and past */}
        {(isLastBookingLoading ||
          isCurrentBookingLoading ||
          bookingLoading) && <Loader />}
        {changeBg === "current" ? (
          <BookingList
            type="current"
            data={currentBookingList}
            isFetchingNextPage={isCurrentBookingFetchingNextPage}
            isLoading={isCurrentBookingLoading}
            isFetching={isCurrentBookingFetching}
            hasNextPage={hasCurrentBookingNextPage}
            fetchNextPage={fetchCurrentBookingNextPage}
            changeStatus={changeStatus}
            setOpen={setOpen}
          />
        ) : (
          // Last Booking
          <BookingList
            type="last"
            data={lastBookingList}
            isFetchingNextPage={isLastBookingFetchingNextPage}
            isLoading={isLastBookingLoading}
            isFetching={isLastBookingFetching}
            hasNextPage={hasLastBookingNextPage}
            fetchNextPage={fetchLastBookingNextPage}
            changeStatus={changeStatus}
            setOpen={setOpen}
          />
        )}
      </div>
      {open ? <TrackingMap setOpen={setOpen} /> : null}
    </>
  );
};

export default Bookings;
