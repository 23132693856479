import { onMessage } from "firebase/messaging";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { chatStore } from "store/chatStore";
import { notificationStore } from "store/notificationStore";
import { messaging, requestPermissions } from "../../../firebase";
import { unreadNotifications } from "store/unreadNotifications";

const useFirebaseNotifications = () => {
  const setChatID = chatStore((state) => state.setChatID);
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const setHasNotification = notificationStore(
    (state) => state.setHasNotification
  );
  const setCount = unreadNotifications((state) => state.setCount);
  const count = unreadNotifications((state) => state.count);
  useEffect(() => {
    requestPermissions();
    onMessage(messaging, (payload) => {
      if (payload.data.screen !== "conversation") {
        setHasNotification(true);
        setCount(count + 1);
      }
      enqueueSnackbar({
        anchorOrigin: { horizontal: "right", vertical: "top" },
        className:
          "flex !justify-start !items-center !bg-primary-color !px-0 !py-0 [&_>_div]:!p-0",
        autoHideDuration: 5000,
        message: (
          <button
            onClick={() => {
              if (payload.data?.screen !== "booking") {
                setChatID(payload.data?.id);
                navigate("/chat/message");
              } else {
                navigate(`/booking/${payload.data?.id}`);
              }
            }}
            className="flex flex-col max-w-[300px] justify-start items-start px-4 py-2"
          >
            <h3 className="text-xl text-bold">
              {i18n.language !== "en"
                ? payload.data?.title
                : payload.notification?.title}
            </h3>
            <p className="text-start line-clamp-3">
              {i18n.language !== "en"
                ? payload.data?.body
                : payload.notification?.body}
            </p>
          </button>
        ),
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default useFirebaseNotifications;
