import { _axios } from "interceptor/http-config";

export const _AuthApi = {
  login: async (data) => {
    return _axios.post("/user/login", data).then((res) => {
      _AuthApi.storeToken(res?.data?.profile?.access_token);
    });
  },
  storeToken: (access_token) => {
    localStorage.setItem("access_token", access_token);
  },

  getToken: () => localStorage.getItem("access_token"),

  destroyToken: async () => {
    await _axios.get("/user/logout?type=web");
    localStorage.removeItem("access_token");
    localStorage.removeItem("name");
  },

  register: async (data) => {
    return _axios.post("/user/register", data);
  },
  verifyEmail: async (data) => {
    return _axios.post("/user/auth/check-code", data);
  },
  resetPass: async (data) => {
    return _axios.post("/user/reset-password", data);
  },
  verifyCode: async (data) => {
    return _axios.post("/user/check-code", data);
  },
  passEdit: async (data) => {
    return _axios.post("/user/edit-password", data);
  },
};
