import { _AuthApi } from "api/auth";
import CompleteProject from "assets/images/profile/Complet Projects.svg";
import RateIcon from "assets/images/profile/Rate.svg";
import WorkExperianceIcon from "assets/images/profile/Work Experiance.svg";
import perhourIcon from "assets/images/profile/perhour.svg";
import { _axios } from "interceptor/http-config";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { servicesStore } from "store/servicesStore";
import { singinStore } from "store/singinStore";
export const useServiceProviderShow = () => {
  const location = useLocation();
  const { t } = useTranslation("index");

  const queryParameters = new URLSearchParams(location.search);
  const [servicesName, subServicesName, serviceProvider] = servicesStore(
    (state) => [
      state.serviceProvider,
      state.servicesName,
      state.subServicesName,
      state.setSubServicesName,
    ]
  );
  // Get all query parameters as an array
  const [favoriteStatus, setFavoriteStatus] = useState({});
  const [loadingFavorite, setLoadingFavorite] = useState(false);
  const queryClient = useQueryClient();
  const favoriteToggle = useMutation(
    async (id) => {
      // Send the update to the server
      return _axios.get(`user/toggle-favourite/${id}`).then((response) =>
        enqueueSnackbar(response.data.message, {
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        })
      );
    },
    {
      onMutate: async (id) => {
        // Optimistically update the favoriteStatus
        // const updatedFavoriteStatus = { ...favoriteStatus };
        // updatedFavoriteStatus[id] = !updatedFavoriteStatus[id];
        // setFavoriteStatus(updatedFavoriteStatus);
        queryClient.invalidateQueries(["favourite"]);

        await queryClient.cancelMutations(""); // Cancel any pending mutations for this key
        return id; // Pass the id as context for the onSettled function
      },
      onError: (error, variables, context) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const { message } = error.response.data;
          Object.keys(message).forEach((field) => {
            const errorMessages = message[field];
            alert(`${field}: ${errorMessages.join(", ")}`);
            enqueueSnackbar(field, errorMessages.join(", "), {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
            });
          });
        }
      },
      onSettled: (id, error, result) => {
        // Handle success or error cases and clear loading state
        if (!error) {
          queryClient.invalidateQueries(["favourite"]);
        } else {
          // If there's an error, revert the optimistic update
          const updatedFavoriteStatus = { ...favoriteStatus };
          updatedFavoriteStatus[id] = !updatedFavoriteStatus[id];
          setFavoriteStatus(updatedFavoriteStatus);
          queryClient.invalidateQueries(["favourite"]);
        }
        queryClient.invalidateQueries([
          "services-provider-profile",
          `Subservices-${subServicesID || SubServicesId}`,
          `provider-${serviceProviderID}`,
        ]);

        setLoadingFavorite(false);
      },
      onSuccess: (response) => {
        setLoadingFavorite(false);
      },
    }
  );

  const handleFavorite = async (id) => {
    setLoadingFavorite(true);
    favoriteToggle.mutateAsync(id);
  };
  const allQueryParameters = {};
  for (const [key, value] of queryParameters.entries()) {
    allQueryParameters[key] = value;
  }
  const url = location.pathname; // G // Replace with your actual URL

  // Define a regular expression to match numbers after "/service-provider/profile/"
  const pattern = /\/service-provider\/profile\/(\d+)\/(\d+)/;

  // Use the regular expression to match and extract the numbers
  const match = pattern.exec(url);
  if (match) {
    // The first captured group (index 1) is the first number
    var SubServicesId = match[1];
    // The second captured group (index 2) is the second number
    var serviceProviderID = match[2];
  }
  const [subServicesID, setSubServicesID] = useState();
  const [subServicesIDLoading, setsubServicesIDLoading] = useState(false);
  const { data: servicesProvider, isLoading: servicesProviderLoading } =
    useQuery(
      [
        "services-provider-profile",
        `Subservices-${subServicesID || SubServicesId}`,
        `provider-${serviceProviderID}`,
      ],
      async () => {
        return _axios
          .get(
            `/user/service-providers/show/${
              subServicesID || SubServicesId
            }/${serviceProviderID}`,
            {
              headers: {
                ...HttpRequestInterceptor(),
              },
            },
            setsubServicesIDLoading(true)
          )
          .then((res) => {
            setsubServicesIDLoading(false);
            return res.data;
          });
      },
      {}
    );
  const hanldeSubServices = (item) => {
    setSubServicesID(item);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (subServicesID) {
      setsubServicesIDLoading(true); // Loading is in progress
    }
  }, [subServicesID]);

  const details = [
    {
      icon: WorkExperianceIcon,
      data: servicesProvider?.service_provider.work_experience,
      text: t("Work Experience"),
    },
    {
      icon: CompleteProject,
      data: servicesProvider?.service_provider.completed_project,
      text: t("Completed Projects"),
    },
    {
      icon: RateIcon,
      data: servicesProvider?.service_provider.rate,
      text: t("User Review"),
    },
    {
      icon: perhourIcon,
      data: servicesProvider?.service_provider?.selected_sub_service?.price,
      text: t("Per Hour"),
    },
  ];
  const servicesProviderData =
    servicesProvider?.service_provider?.sub_services || [];
  const [currentIndex, setCurrentIndex] = useState(0);

  const itemsToShow = servicesProviderData.slice(
    currentIndex,
    currentIndex + 3
  );

  const showNextItems = () => {
    if (
      itemsToShow.length < 3 ||
      currentIndex.length === servicesProviderData.length
    ) {
      return;
    }
    setCurrentIndex((prevIndex) => prevIndex + 3);
  };
  const showPrevItems = () => {
    if (currentIndex === 0) return;
    setCurrentIndex((prevIndex) => prevIndex - 3);
  };

  const navigate = useNavigate();
  const acces_token = _AuthApi.getToken();
  const hanldeViewOrder = () => {
    navigate(`order/${SubServicesId}`);
  };
  const [openSignIn, setOpenSignIn] = singinStore((state) => [
    state.openSignIn,
    state.setOpenSignIn,
  ]);
  return {
    t,
    details,
    openSignIn,
    acces_token,
    itemsToShow,
    currentIndex,
    servicesName,
    setOpenSignIn,
    showNextItems,
    showPrevItems,
    handleFavorite,
    favoriteStatus,
    hanldeViewOrder,
    loadingFavorite,
    serviceProvider,
    subServicesName,
    servicesProvider,
    hanldeSubServices,
    servicesProviderData,
    subServicesIDLoading,
    servicesProviderLoading,
    navigate,
  };
};
