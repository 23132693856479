import { yupResolver } from "@hookform/resolvers/yup";
import { _AuthApi } from "api/auth";
import { _axios } from "interceptor/http-config";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useValidation } from "../validation/useValidation";

export const useSignInLogic = ({ setOpenSignIn }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("auth");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const { schema } = useValidation();
  const { mutate } = useMutation((data) => createPost(data));
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const [termsOfUs, setOpenTerms] = useState(false);
  const [openPricavyDialog, setopenPricavyDialog] = useState(false);
  const handleClose = () => {
    setOpenSignIn(false);
  };
  const hanldeShowPass = () => {
    setShowPass(!showPass);
  };
  const token = localStorage.getItem("firebaseToke");
  async function createPost(data, e) {
    let body = {};
    setLoading(true);
    if (token) {
      body = { ...data, type: "web", device_token: token };
    } else {
      body = data;
    }
    await _AuthApi
      .login(body, navigate, enqueueSnackbar)
      .then((res) => {
        Swal.fire(t("Login successfully"));
        setOpenSignIn(false);

        // navigate("/");
      })
      .catch((error) => {
        const errorResponse = error?.response?.data;
        if (!errorResponse) {
          Swal.fire("An unexpected error occurred. Please try again later");
        }
        if (typeof errorResponse?.message === "object") {
          Object.values(errorResponse.message).forEach((errorMessages) => {
            errorMessages.forEach((errorMessage) => {
              Swal.fire(`${errorMessage}`);
            });
          });
        }
        if (typeof errorResponse?.message === "string") {
          Swal.fire(`${errorResponse?.message}`);
        }
      })
      .finally((res) => {
        setLoading(false);
      });
  }
  const onSubmit = async (input) => {
    mutate(input);
  };

  const loginMutation = useMutation(
    () => _axios.get("/user/auth/google").then((res) => res.data.url),
    {
      onMutate: () => {
        // You can use this to set some loading state or optimistic updates
        setGoogleLoading(true);
      },
      onSuccess: (url) => {
        if (url) {
          window.location.href = url;
        } else {
          enqueueSnackbar("URL Invalid", {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
          });
        }
      },
      onError: (error) => {
        // Handle errors here
        console.error("Login mutation failed:", error);
        enqueueSnackbar("Login failed", {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
        });
        setGoogleLoading(false);
      },
    }
  );
  const login = () => {
    // You can perform additional logic here before calling the mutation
    setGoogleLoading(true);
    loginMutation.mutate();
  };
  return {
    t,
    login,
    loading,
    showPass,
    navigate,
    onSubmit,
    handleClose,
    handleSubmit,
    hanldeShowPass,
    register,
    errors,
    googleLoading,
    setopenPricavyDialog,
    openPricavyDialog,
    termsOfUs,
    setOpenTerms,
    // userData,
    // responseFacebook,
  };
};
