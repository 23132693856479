import Register from "pages/Auth/Register/Register";

const RegisterModal = ({ openSignUp, setOpenSignUp }) => {
  const hanldeOpenSignUp = () => {
    setOpenSignUp(!openSignUp);
  };
  return (
    openSignUp && (
      <>
        <Register setOpenSignUp={setOpenSignUp} />
        <div
          className="fixed top-0 z-10 w-screen h-screen bg-black/75"
          onClick={hanldeOpenSignUp}
        ></div>
      </>
    )
  );
};

export default RegisterModal;
