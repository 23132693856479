import backIcon from "assets/icons/svg/Back.svg";
import cameraIcon from "assets/icons/svg/account/cameraIcon.svg";
import curv from "assets/icons/svg/account/curvRes.svg";
import ProfileImage from "assets/images/profile/ProfileImage.png";
import Loader from "components/styled/Loader";
import { useEditProfile } from "../hooks/useEditProfile";
import ConfirmPassword from "./ConfirmPassword";

const EditProfile = ({ GoogleLogin }) => {
  const {
    t,
    loading,
    confirmPassword,
    getValues,
    setLoading,
    setEditProfile,
    imageLink,
    editProfile,
    handleImage,
    handleSubmit,
    onSubmit,
    register,
    errors,
    setConfirmPassword,
  } = useEditProfile();
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        confirmPassword && (
          <>
            <ConfirmPassword
              email={getValues("email")}
              name={getValues("name")}
              setLoading={setLoading}
              loading={loading}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
              setEditProfile={setEditProfile}
            />
          </>
        )
      )}
      <div className="flex flex-col items-center justify-center h-full lg:mx-10 w-1/1">
        <div className="flex items-start h-1/6 lg:hidden">
          <img src={curv} alt="" className="w-screen" />
          <div
            onClick={() => setEditProfile(false)}
            className="absolute flex gap-5 p-5 mt-5"
          >
            <button>
              <img src={backIcon} alt="" />
            </button>
            <p className="text-xl font-bold">{t("Edit Profile")}</p>
          </div>
        </div>
        {/* Lg: hidden */}
        <div className="flex items-center justify-center lg:hidden ">
          <img
            src={imageLink?.ImageLink ? imageLink?.ImageLink : ProfileImage}
            className="object-cover w-40 h-40 rounded-full -translate-y-9 md:-translate-y-9"
            alt=""
          />
          {editProfile && (
            <div className="absolute z-10 flex items-center justify-center w-40 h-40 p-10 transform rounded-full bg-gradient-to-br from-black/40 to-white/40 -translate-y-9 md:-translate-y-9">
              <input
                type="file"
                id="image"
                className="hidden"
                onChange={(e) => handleImage(e)}
              />
              <label
                htmlFor="image"
                className="flex items-center justify-center w-full "
              >
                <img src={cameraIcon} alt="" name="image" className="w-5 " />
              </label>
            </div>
          )}
        </div>
        {/* Lg: hidden */}
        {GoogleLogin === 1 ? (
          <form
            className="flex flex-col w-full gap-5 px-10 lg:px-10 lg:gap-20 "
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-2 ">
              <label>{t("Name")}</label>
              <input
                placeholder={t("Enter Your Name")}
                type="text"
                name="name"
                {...register("name")}
                className="p-3 border w-1/1 border-primary-color rounded-3xl lg:p-5"
              />
            </div>
            <div className="flex flex-col gap-2 ">
              <label>{t("Email")}</label>
              <input
                placeholder={t("Enter Your Email")}
                type="email"
                name="email"
                {...register("email")}
                className="p-3 border w-1/1 border-primary-color rounded-3xl lg:p-5"
              />
            </div>

            <div className="flex flex-col items-center justify-center w-full my-10">
              <button
                type="submit"
                className="relative w-full h-12 text-white rounded-3xl bg-primary-color"
              >
                <p>{t("Submit")}</p>
              </button>
            </div>
          </form>
        ) : (
          <div className="flex flex-col w-full gap-5 px-10 lg:px-10 lg:gap-20">
            <div className="flex flex-col gap-2 ">
              <label>{t("Name")}</label>
              <input
                placeholder={t("Enter Your Name")}
                type="text"
                name="name"
                {...register("name")}
                className="p-3 border w-1/1 border-primary-color rounded-3xl lg:p-5"
              />
              {errors.name && (
                <span className="text-red-500">{errors.name.message}</span>
              )}
            </div>
            <div className="flex flex-col gap-2 ">
              <label>{t("Email")}</label>
              <input
                placeholder={t("Enter Your Email")}
                type="email"
                name="email"
                {...register("email")}
                className="p-3 border w-1/1 border-primary-color rounded-3xl lg:p-5"
              />
              {errors.email && (
                <span className="text-red-500">{errors.email.message}</span>
              )}
            </div>

            <div className="flex flex-col items-center justify-center w-full my-10">
              <button
                onClick={() => setConfirmPassword(!confirmPassword)}
                type="button"
                className="relative w-full h-12 text-white rounded-3xl bg-primary-color"
              >
                <p>{t("Update")}</p>
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditProfile;
