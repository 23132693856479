import { useInfiniteQuery } from "react-query";
import { _AccountApi } from "api/account/account";

export const useBooking = () => {
  const {
    data: currentBookingList,
    fetchNextPage: fetchCurrentBookingNextPage,
    hasNextPage: hasCurrentBookingNextPage,
    isFetchingNextPage: isCurrentBookingFetchingNextPage,
    isLoading: isCurrentBookingLoading,
    isFetching: isCurrentBookingFetching,
  } = useInfiniteQuery({
    queryKey: ["booking-current"],
    queryFn: _AccountApi.bookingsCurrent,
    getNextPageParam: (lastPage) => {
      const pagination = lastPage.pagination;
      return pagination.next_page_url
        ? pagination.current_page + 1 || 0
        : undefined;
    },
  });
  const {
    data: lastBookingList,
    fetchNextPage: fetchLastBookingNextPage,
    hasNextPage: hasLastBookingNextPage,
    isFetchingNextPage: isLastBookingFetchingNextPage,
    isLoading: isLastBookingLoading,
    isFetching: isLastBookingFetching,
  } = useInfiniteQuery({
    queryKey: ["booking-last"],
    queryFn: _AccountApi.bookingsLast,
    getNextPageParam: (lastPage) => {
      const pagination = lastPage.pagination;
      return pagination.next_page_url
        ? pagination.current_page + 1 || 0
        : undefined;
    },
  });
  return {
    currentBookingList,
    fetchCurrentBookingNextPage,
    hasCurrentBookingNextPage,
    isCurrentBookingFetchingNextPage,
    isCurrentBookingLoading,
    isCurrentBookingFetching,
    lastBookingList,
    fetchLastBookingNextPage,
    hasLastBookingNextPage,
    isLastBookingFetchingNextPage,
    isLastBookingLoading,
    isLastBookingFetching,
  };
};
