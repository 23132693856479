import React, { forwardRef } from "react";
import iphone from "assets/images/home/iPhone 13 Pro.png";
import { useServices } from "hooks/home/useServices";
import googlePlay from "assets/images/home/google.png";
import AppStore from "assets/images/home/App.png";
import { useNavigate } from "react-router-dom";
import OfferManyResponsive from "./OfferManyResponsive";
import Loader from "components/styled/Loader";
import { useTranslation } from "react-i18next";
const OfferMany = forwardRef((_, ref) => {
  const { t } = useTranslation("index");
  const { data, isLoading } = useServices();
  const navigate = useNavigate();

  const handleViewSubServices = (id) => {
    navigate(`services/view/${id}`);
  };

  const renderServiceItem = (item, index, position) => (
    <div
      key={index}
      onClick={() => handleViewSubServices(item.id)}
      className={`absolute flex items-center hover:scale-110 cursor-pointer duration-1000 w-[194.08px] h-[73.51px] gap-2 px-4 text-[21.51px] font-medium bg-white ${position} rounded-[17.93px] drop-shadow-xl text-sixth-color Roboto`}
    >
      <img src={item.image} className="w-5" alt="" />
      <p>{item.name}</p>
    </div>
  );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div ref={ref} className="items-center justify-center hidden lg:flex">
          <div className="flex items-end justify-center rounded-[43.92px] rounded-tr-[43.92px] relative w-[1264px] bg-primary-color h-[620px] rounded-r-none">
            <div className="absolute top-0 left-0 flex items-end justify-end w-1/4 p-4 bg-white rounded-br-[43.92px]">
              <p className="text-5xl montserrat text-[#333333] leading-[50px]">
                {t("home.We've got you covered!")}{" "}
                <span className="text-6xl text-fifth-color">.</span>
              </p>
            </div>

            {data?.services?.map((item, index) =>
              renderServiceItem(
                item,
                index,
                index === 0
                  ? "right-80 top-28"
                  : index === 1
                  ? "right-48 top-64"
                  : index === 2
                  ? "right-72 top-[380px] "
                  : index === 3
                  ? "left-[340px] top-[220px]"
                  : index === 4
                  ? "left-[210px] top-[380px] "
                  : "left-[400px] top-[480px]"
              )
            )}

            <img src={iphone} alt="mibo" className="" />
            <div className="absolute right-0 flex items-center justify-center gap-3 w-1/4 p-4 bg-white rounded-tl-[43.92px] ">
              <a href="#" className="duration-1000 w-96 hover:scale-110">
                <img src={AppStore} className="" alt="" />
              </a>
              <a href="" className="duration-1000 w-96 hover:scale-110">
                <img src={googlePlay} className="" />
              </a>
            </div>
          </div>
        </div>
      )}

      <div className="block lg:hidden">
        <OfferManyResponsive />
      </div>
    </>
  );
});

export default OfferMany;
