import { create } from "zustand";

export const chatStore = create((set) => ({
  chatID: false,
  attachmentRes: false,
  receiveMess: [],
  setReceiveMess: (data) => {
    set((state) => {
      // Create a Set to store unique message IDs
      const uniqueMessageIds = new Set(
        state.receiveMess.map((item) => item?.id)
      );

      // Filter out messages with IDs already present in the Set
      const newData = Array.isArray(data)
        ? data.filter((newItem) => !uniqueMessageIds.has(newItem?.id))
        : !uniqueMessageIds.has(data?.id)
        ? [data]
        : [];

      // Update state by appending unique messages to the existing ones
      return {
        receiveMess: [...state.receiveMess, ...newData],
      };
    });
  },
  // setReceiveMess: (data) => {
  //   set(() => ({ receiveMess: Array.isArray(data) ? data : [data] }));
  // },

  setChatID: (data) => {
    set(() => ({ chatID: data }));
  },

  setAttachmentRes: (data) => {
    set(() => ({ attachmentRes: data }));
  },
}));
