import { useQuery } from "react-query";
import { _ProfileApi } from "api/profile/profile";

export const useProfile = (id) => {
  const { data, isLoading } = useQuery(
    ["profile"],
    () => _ProfileApi.index().then((res) => res),
    {
      keepPreviousData: true,
    }
  );
  return {
    data,
    isLoading,
  };
};
