import React from "react";
import notFound from "assets/icons/404/404.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("index");

  return (
    <section className="flex flex-col items-center justify-center gap-10 px-10">
      <img src={notFound} alt="" className="lg:w-1/3" />
      <button
        className="px-5 py-2 text-white md:text-xl md:py-4 md:px-10 rounded-2xl bg-primary-color"
        onClick={() => navigate("/")}
      >
        {t("Back To Home")}
      </button>
    </section>
  );
};

export default NotFound;
