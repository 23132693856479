import checkicon from "assets/icons/chat/checkicon.svg";
import file from "assets/icons/chat/file.svg";
import msgError from "assets/icons/chat/msgError.svg";
import clock from "assets/icons/chat/msgloader.svg";
import locationOrder from "assets/icons/chat/sendIcon.svg";
import profileIcon from "assets/icons/navBar/profileIcon.png";
import backIcon from "assets/icons/svg/Back.svg";
import ChatLoader from "components/styled/ChatLoader";
import Loader from "components/styled/Loader";
import Block from "../components/Block/Block";
import ClearChat from "../components/ClearChat/ClearChat";
import ImageModal from "../components/ImageModal/ImageModal";
import ReportUser from "../components/ReportUser/ReportUser";
import { useConversation } from "../hooks/useConversation";
import Attachment from "./Attachment";
const Conversation = () => {
  const {
    t,
    chat,
    chatID,
    username,
    navigate,
    setChatID,
    isLoading,
    openModal,
    closeModal,
    openDrawer,
    formatDate,
    msgLoading,
    chatActions,
    blockAction,
    newMessage,
    handleImage,
    handleDrawer,
    loadingScroll,
    setOpenDrawer,
    selectedImage,
    attachmentRes,
    setChatActions,
    setNewMessage,
    selectedImages,
    lastMessageRef,
    firstID,
    setAttachmentRes,
    handleSendMessage,
    handleRemoveImage,
    messagesContainerRef,
    allMessagesCombined,
    setClearChat,
  } = useConversation();
  return (
    <>
      {isLoading && <Loader />}
      {chatActions.block && (
        <Block
          setChatActions={setChatActions}
          chatID={chatID}
          username={username}
          blockAction={blockAction}
        />
      )}
      {chatActions.clearChat && (
        <ClearChat
          chatID={chatID}
          setClearChat={setClearChat}
          setChatActions={setChatActions}
          username={username}
        />
      )}
      {chatActions.reportUser && (
        <ReportUser
          chatID={chatID}
          setChatActions={setChatActions}
          username={username}
        />
      )}
      {attachmentRes ? (
        <Attachment />
      ) : (
        <div className="w-full mt-2 flex flex-col justify-between">
          <div className="flex items-center justify-between gap-3 mx-2 bg-white rounded-lg md:mx-5 md:p-2">
            <div className="hidden gap-2 md:flex">
              <p className="text-xl"> {chat?.user_name}</p>
            </div>
            <div className="flex gap-3 md:gap-5 md:hidden">
              <button
                onClick={() => {
                  navigate("/chat/message");
                  setChatID(false);
                }}
              >
                <img src={backIcon} alt="" />
              </button>
              <div
                className="flex items-center gap-2"
                onClick={() => setAttachmentRes(chatID)}
              >
                <img src={profileIcon} className="md:h-16 md:w-16" alt="" />
                <p>{chat?.user_name}</p>
              </div>
            </div>
            <div className="relative">
              <div className="flex flex-col rotate-90 rounded-full cursor-pointer md:hover:scale-110">
                <span
                  className="text-[#AFB5C0] text-2xl"
                  onClick={handleDrawer}
                >
                  ...
                </span>
              </div>
              <div className="absolute right-0 w-40 text-lg -translate-x-6 translate-y-4 bg-white rounded-lg rounded-tr-none shadow-2xl">
                {openDrawer && (
                  <div className="flex flex-col gap-3 p-3 text-sm md:text-lg ">
                    <p
                      onClick={() => {
                        setChatActions({ ...chatActions, block: true });
                        setOpenDrawer(false);
                      }}
                      className="cursor-pointer hover:scale-105"
                    >
                      {chat?.is_block === 0 ? t("Block") : t("UnBlock")}
                    </p>
                    <p
                      onClick={() => {
                        setChatActions({ ...chatActions, clearChat: true });
                        setOpenDrawer(false);
                      }}
                      className="cursor-pointer hover:scale-105"
                    >
                      {t("Clear Chat")}
                    </p>
                    <p
                      onClick={() => {
                        setChatActions({ ...chatActions, reportUser: true });
                        setOpenDrawer(false);
                      }}
                      className="cursor-pointer hover:scale-105"
                    >
                      {t("Report user")}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col h-full md:justify-between">
            <div
              className="flex flex-col justify-start gap-2 h-[800px] overflow-scroll p-2"
              ref={messagesContainerRef}
            >
              {loadingScroll && <ChatLoader />}
              {allMessagesCombined
                .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                .map((item, index, array) => (
                  <div
                    key={index}
                    ref={
                      firstID && firstID === item.id
                        ? lastMessageRef
                        : undefined
                    }
                    className={`flex ${
                      item.sender_type === "user"
                        ? "justify-end float-right"
                        : "justify-start float-left"
                    } w-full`}
                  >
                    <div
                      className={`${
                        item.sender_type === "user"
                          ? "text-white w-1/1 max-w-lg md:ml-5 md:w-1/1 h-1/1 p-2 bg-primary-color rounded-lg rounded-br-none border border-third-color "
                          : "flex flex-col float-left  w-1/1 max-w-lg md:ml-5 my-2 md:my-5 md:w-1/1 h-1/1 w-1/1 md:w-1/1 h-1/1 p-2 bg-white rounded-lg rounded-bl-none border border-third-color "
                      }`}
                    >
                      <p>{item?.text || item?.content}</p>
                      {item?.media ? (
                        <div className="flex flex-wrap gap-2 mt-2">
                          {item?.media?.map((mediaUrl, mediaIndex) => (
                            <img
                              key={mediaIndex}
                              src={mediaUrl}
                              onClick={() => openModal(mediaUrl)}
                              // onClick={() => window.open(mediaUrl, "_blank")}
                              alt={`Images ${mediaIndex + 1}`}
                              className="rounded-md cursor-pointer w-60"
                            />
                          ))}
                        </div>
                      ) : (
                        item?.images.map((image, imageIndex) => (
                          <img
                            key={imageIndex}
                            src={URL.createObjectURL(image)}
                            onClick={() =>
                              openModal(URL.createObjectURL(image))
                            }
                            alt={`Images ${imageIndex}`}
                            className="rounded-md cursor-pointer w-60"
                          />
                        ))
                      )}
                      <div className="flex items-center justify-end gap-2 w-1/1">
                        <p className="text-gray-300">
                          {formatDate(item?.timestamp)}
                        </p>
                        <div className="">
                          {item.sender_type === "user" && (
                            <>
                              {index === array.length - 1 ? (
                                <>
                                  {msgLoading ? (
                                    <img
                                      src={clock}
                                      alt=""
                                      className="w-5 h-5"
                                    />
                                  ) : (
                                    <>
                                      {!msgLoading ? (
                                        <img
                                          src={checkicon}
                                          alt=""
                                          className="w-5 h-5"
                                        />
                                      ) : (
                                        <img
                                          src={msgError}
                                          alt=""
                                          className="w-5 h-5"
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <img
                                  src={checkicon}
                                  alt=""
                                  className="w-5 h-5"
                                />
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {chat?.is_block === 1 ? (
              <p className="w-full text-center">
                {t("You cant text this person now because you blocked contact")}
              </p>
            ) : (
              <form
                onSubmit={handleSendMessage}
                className="relative flex gap-2 px-2 md:px-5 md:gap-5 "
              >
                <textarea
                  required={selectedImages.length > 0 ? false : true}
                  type="text"
                  placeholder="Message.."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  // onKeyDown={handleTextareaKeyDown}
                  className="w-full rounded-lg placeholder:p-2 resize-none p-2 bg-[#E6F7FE] md:bg-white h-40 "
                ></textarea>
                {selectedImages && (
                  <div className="absolute flex flex-wrap gap-10 mt-2 right-28">
                    {selectedImages.length > 0 &&
                      selectedImages.map((photo, index) => (
                        <div className="flex" key={index}>
                          <div className="absolute z-40 flex items-center justify-center w-12 h-full rounded-lg bg-black/50">
                            <div
                              className="absolute z-20 flex items-center justify-center w-2 h-2 p-2 text-white rounded-full cursor-pointer bg-white/50"
                              onClick={() => handleRemoveImage(index)}
                            >
                              <p className="absolute">x</p>
                            </div>
                          </div>
                          <img
                            src={URL.createObjectURL(photo)}
                            alt={`Mibo ${index}`}
                            className="relative w-12 rounded-lg"
                          />
                        </div>
                      ))}
                  </div>
                )}

                <label
                  htmlFor="file"
                  className="absolute mt-3 cursor-pointer right-24 md:right-20"
                >
                  <img src={file} alt="" className="w-5" />
                </label>
                <input
                  type="file"
                  id="file"
                  className="hidden right-5"
                  onChange={handleImage}
                  multiple
                />

                <div>
                  <button className="w-10 h-10 p-1 rounded-full bg-primary-color">
                    <img src={locationOrder} className="w-10 p-2 " alt="" />
                  </button>
                </div>
              </form>
            )}
            {selectedImage && (
              <ImageModal imageUrl={selectedImage} onClose={closeModal} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Conversation;
