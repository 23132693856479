import { _axios } from "interceptor/http-config";
import { enqueueSnackbar } from "notistack";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";

export const _AccountApi = {
  privacy: async () => {
    return _axios
      .get(`/user/privacy`, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data)
      .catch((error) =>
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        })
      );
  },
  terms: async () => {
    return _axios
      .get(`/user/terms`, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data)
      .catch((error) =>
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        })
      );
  },
  cookies: async () => {
    return _axios
      .get(`/user/cookie`, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data)
      .catch((error) =>
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        })
      );
  },
  faq: async () => {
    return _axios
      .get(`/user/FAQ`, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data)
      .catch((error) =>
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        })
      );
  },
  support: async () => {
    return _axios
      .get(`/user/support`, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data)
      .catch((error) =>
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        })
      );
  },
  bookingsLast: async ({ pageParam = 1 }) => {
    return _axios
      .get(`/user/booking/get-last?page=${pageParam}&count=10`, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data)
      .catch((error) =>
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        })
      );
  },
  bookingsCurrent: async ({ pageParam = 1 }) => {
    return _axios
      .get(`/user/booking/get-current?page=${pageParam}&count=10`, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data)
      .catch((error) =>
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        })
      );
  },
};
