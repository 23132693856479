import backIcon from "assets/icons/svg/Back.svg";
import arrowIcon from "assets/icons/svg/account/ArrowLeft.svg";
import Loader from "components/styled/Loader";
import { FaqItem } from "./FaqItem";
import { useLogic } from "./hooks/useLogic";

const FAQ = () => {
  const { t, activeIndex, setActiveIndex, data, isLoading, navigate } =
    useLogic();
  return (
    <div className="lg:px-16 mt-4">
      <div className="flex items-start w-full h-full lg:hidden">
        <div className="flex gap-5 p-5 mt-5 " onClick={() => navigate(-1)}>
          <button>
            <img src={backIcon} alt="" />
          </button>
          <p className="text-xl font-bold">{t("FAQ’s")}</p>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center w-full rounded-lg lg:p-10 lg:bg-third-color ">
        <div className="hidden w-full px-10 lg:flex-row lg:flex gap-14 ">
          <img
            src={arrowIcon}
            alt=""
            onClick={() => navigate(-1)}
            className="cursor-pointer"
          />
          <p className="text-2xl font-bold">{t("FAQ’s")}</p>
        </div>

        <div className="lg:pl-12 flex flex-col w-full gap-5 py-5 lg:gap-5 max-h-[600px] overflow-scroll">
          {isLoading && <Loader />}
          {data?.faqs.map((faq, index) => (
            <div key={index} className="flex">
              <FaqItem
                index={index}
                question={faq.question}
                answer={faq.answer}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
