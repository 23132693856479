import { _AuthApi } from "api/auth";
import arrow from "assets/icons/ArrowDown.svg";
import lang from "assets/icons/Lang.png";
import { Fade } from "hamburger-react";
import { useServices } from "hooks/home/useServices";
import { useEffect, useState } from "react";
import { servicesStore } from "store/servicesStore";
const NavBarResponsive = ({
  hanldeOpenSignUp,
  hanldeOpenSignIn,
  t,
  toggleLang,
  direction,
  openSignIn,
  openSignUp,
  navigate,
}) => {
  const { data } = useServices();
  const [isOpen, setOpen] = useState(false);
  const [openServices, setOpenServices] = useState(false);
  useEffect(() => {
    (openSignIn || openSignUp) && setOpen(false);
  }, [openSignIn, openSignUp]);
  useEffect(() => {
    isOpen === false && setOpenServices(false);
  }, [isOpen]);
  const access_token = _AuthApi.getToken();
  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled
      if (window.scrollY > 0) {
        setOpen(false); // Close the services if open
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleViewSubServices = (id) => {
    navigate(`services/view/${id}`);
    setOpen(false);
  };
  const [setServicesName] = servicesStore((state) => [state.setServicesName]);

  return (
    <>
      {/* {isOpen && ( */}
      <div
        className={`fixed h-screen top-0 end-0 bottom-0 w-screen z-40 bg-black/75  ${
          isOpen ? "-translate-x-0" : "translate-x-full"
        }`}
        onClick={() => setOpen(false)}
      ></div>
      {/* )} */}
      <div className="relative">
        <Fade toggled={isOpen} toggle={setOpen} size={20} />
        {access_token ? (
          <div
            className={`fixed h-screen end-0 top-0 bottom-0 overflow-hidden z-50 bg-white drop-shadow-xl shadow-xl transition-transform duration-1000 transform ${
              isOpen ? "-translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="flex justify-end">
              <Fade toggled={isOpen} toggle={setOpen} size={20} />
            </div>
            <div className="flex flex-col h-full gap-2 text-[18px] w-60 text-[#62646a] Roboto !px-5">
              <p
                onClick={() => {
                  navigate("/account");
                  setOpen(false);
                }}
                className="flex items-start p-1 hover:bg-third-color"
              >
                {t("Account")}
              </p>
              <div className="w-full h-[1px] border border-neutral-200"></div>
              <p
                onClick={() => {
                  navigate("/notification");
                  setOpen(false);
                }}
                className="flex items-start p-1 hover:bg-third-color"
              >
                {t("Notification")}
              </p>
              <div className="w-full h-[1px] border border-neutral-200"></div>
              <p
                onClick={() => {
                  navigate("/chat/message");
                  setOpen(false);
                }}
                className="flex items-start p-1 hover:bg-third-color"
              >
                {t("Chat")}
              </p>
              <div className="w-full h-[1px] border border-neutral-200"></div>
              <p
                onClick={() => {
                  navigate("/favorite");
                  setOpen(false);
                }}
                className="flex items-start p-1 hover:bg-third-color"
              >
                {t("Favorite")}
              </p>
              <div className="w-full h-[1px] border border-neutral-200"></div>

              <div>
                <div
                  onClick={() => setOpenServices(!openServices)}
                  className={`flex items-center cursor-pointer p-1 gap-24 ${
                    openServices ? "bg-third-color rounded-md " : ""
                  }`}
                >
                  <p>{t("Services")}</p>
                  <img
                    src={arrow}
                    className={`${
                      openServices ? "rotate-180" : ""
                    } transition-all duration-1000 w-4`}
                    alt="Arrow"
                  />
                </div>
                <div
                  className={`overflow-hidden transition-all duration-1000 flex flex-col  ${
                    openServices ? "max-h-full" : "max-h-0"
                  }`}
                >
                  {data?.services?.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        handleViewSubServices(item.id);
                        setServicesName(item.name);
                      }}
                      className="flex items-center gap-4 border !border-t-0 !border-l-0 !border-r-0 !border-third-color py-2"
                    >
                      <img
                        src={item.image}
                        className="w-8"
                        alt="ServiceImage"
                      />
                      <p className="text-sm">{item.name}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-full h-[1px] border border-neutral-200"></div>

              <p onClick={toggleLang} className="flex items-start gap-2 p-1">
                {direction === "rtl" ? "English" : "Deutsch"}
                <img
                  src={lang}
                  alt="mibo"
                  className="object-contain w-4 h-full"
                />
              </p>
            </div>
          </div>
        ) : (
          <div
            className={`fixed h-screen end-0 top-0 bottom-0 overflow-hidden z-50 bg-white drop-shadow-xl shadow-xl transition-transform duration-1000 transform ${
              isOpen ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="flex justify-end">
              <Fade toggled={isOpen} toggle={setOpen} size={20} />
            </div>
            <div className="flex flex-col h-full gap-3 text-[18px] w-60 text-[#62646a] Roboto !px-5">
              <p
                onClick={hanldeOpenSignUp}
                className="flex items-center justify-center w-36  py-2 text-center text-white bg-black rounded-[5px]"
              >
                {t("Join")} Mibo
              </p>
              <p
                onClick={hanldeOpenSignIn}
                className="flex items-start p-1 hover:bg-third-color"
              >
                {t("Sign in")}
              </p>

              <div>
                <div
                  onClick={() => setOpenServices(!openServices)}
                  className={`flex items-center cursor-pointer p-1 gap-24 ${
                    openServices ? "bg-third-color rounded-md " : ""
                  }`}
                >
                  <p>{t("Services")}</p>
                  <img
                    src={arrow}
                    className={`${
                      openServices ? "rotate-180" : ""
                    } transition-all duration-1000 w-4`}
                    alt="Arrow"
                  />
                </div>
                <div
                  className={`overflow-hidden transition-all duration-1000 flex flex-col  ${
                    openServices ? "max-h-full" : "max-h-0"
                  }`}
                >
                  {data?.services?.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => handleViewSubServices(item.id)}
                      className="flex items-center gap-4 border !border-t-0 !border-l-0 !border-r-0 !border-third-color py-2"
                    >
                      <img
                        src={item.image}
                        className="w-8"
                        alt="ServiceImage"
                      />
                      <p className="text-sm">{item.name}</p>
                    </div>
                  ))}
                </div>
              </div>

              <p onClick={toggleLang} className="flex items-start gap-2 p-1">
                {direction === "rtl" ? "English" : "Deutsch"}
                <img
                  src={lang}
                  alt="mibo"
                  className="object-contain w-4 h-full "
                />
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NavBarResponsive;
