import { yupResolver } from "@hookform/resolvers/yup";
import { _axios } from "interceptor/http-config";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { ImageDataContext } from "store/ImageDataContext";
import Swal from "sweetalert2";
import * as yup from "yup";
const schema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "The Password must be of 8 characters")
    .max(30, "The Password must be of 30 characters"),
});

export const useConfirmPassword = (
  email,
  name,
  loading,
  setLoading,
  confirmPassword,
  setConfirmPassword,
  setEditProfile
) => {
  const navigate = useNavigate();
  const { t } = useTranslation("index");
  const { imagesData } = useContext(ImageDataContext);
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const { mutate } = useMutation((data) => createPost(data));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const queryClient = useQueryClient();

  async function createPost(data) {
    setLoading(true);
    await _axios
      .post("/user/profile", data, setLoading, navigate)
      .then((response) => {
        setLoading(true);
        queryClient.invalidateQueries(["profile"]);
        setEditProfile(false);
      })
      .catch((error) => {
        const errorResponse = error?.response?.data;
        if (errorResponse && errorResponse.message) {
          Object.values(errorResponse.message).forEach((errorMessages) => {
            errorMessages.forEach((errorMessage) => {
              Swal.fire(`${errorMessage}`);
            });
          });
        }
      })
      .finally(() => {
        setLoading(false);
        queryClient.invalidateQueries(["profile"]);
        setConfirmPassword(false);
      });
  }

  const onSubmit = async (input) => {
    const formData = new FormData();
    imagesData.find((item) => formData.append("image", item.Image) !== null);
    name && formData.append("name", name);
    input.password && formData.append("password", input.password);
    email && formData.append("email", email);
    mutate(formData);
  };
  return { t, handleSubmit, onSubmit, register, errors };
};
