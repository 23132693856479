import { _AuthApi } from "api/auth";
import { _axios } from "interceptor/http-config";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { servicesStore } from "store/servicesStore";
import { singinStore } from "store/singinStore";
import { useLocationStore } from "store/useLocationStore";
export const useSubServices = () => {
  const params = useParams();
  const navigate = useNavigate();
  const subServicesRef = useRef(null);
  const [data, setData] = useState([]);
  const { t } = useTranslation("index");
  const access_token = _AuthApi.getToken();
  const [currentPage, setCurrentPage] = useState(0);
  const [subServiceID, setSubServiceID] = useState(null);
  const [openRateMenu, setOpenRateMenu] = useState(false);
  const [openPriceMenu, setOpenPriceMenu] = useState(false);
  const [setOpenSignIn] = singinStore((state) => [state.setOpenSignIn]);
  const [servicesProviderLoading, setServicesProviderLoading] = useState(false);
  const [locationID, setLocationID] = useLocationStore((state) => [
    state.locationID,
    state.setLocationID,
  ]);
  const [
    servicesState,
    servicesName,
    subServicesName,
    setSubServicesName,
    setServiceProvider,
  ] = servicesStore((state) => [
    state.servicesState,
    state.servicesName,
    state.subServicesName,
    state.setSubServicesName,
    state.setServiceProvider,
  ]);

  const { data: subServicesData, isLoading: subServicesLoading } = useQuery(
    ["sub-service", `id-${params.id}`],
    async () => {
      const response = await _axios.get(
        `/user/sub-service/${servicesState || params.id}`,
        { headers: { ...HttpRequestInterceptor() } }
      );
      const subServiceData = response.data?.sub_service;
      return subServiceData.map((subService) => ({
        ...subService,
        subServicesId: subService.id,
      }));
    },
    {}
  );
  const viewProfile = (id) => {
    navigate(`/service-provider/profile/${subServiceID}/${id}`);
  };
  const servicesProvider = (subServiceId, option) => {
    console.log("osdbject");
    setServicesProviderLoading(true);
    setSubServiceID(subServiceId);
    _axios
      .get(
        `/user/service-providers/${subServiceId}?count=250&page=${
          currentPage + 1
        }${
          option
            ? `&sortBy=${option.sort}&dir=${option.dir}${
                option.locationID ? `&location_id=${option.locationID}` : ""
              }`
            : ""
        }`
      )
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const { message } = error.response.data;
          Object.keys(message).forEach((field) => {
            const errorMessages = message[field];
            alert(`${field}: ${errorMessages.join(", ")}`);
          });
        }
      })
      .finally(() => {
        setServicesProviderLoading(false);
      });
  };
  const itemsPerPage = data?.pagination?.per_page || 0; // Number of items per page
  const totalItems = data?.pagination?.total || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Function to handle page change
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };
  // Calculate the start and end index for the current page
  // const startIndex = currentPage * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  useEffect(() => {
    if (currentPage >= 0 && subServiceID) {
      console.log("object");
      servicesProvider(subServiceID);
    }
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, subServiceID]);

  const scrollToSubServices = () => {
    if (subServicesRef.current) {
      subServicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const togglePriceMenu = () => {
    setOpenPriceMenu(!openPriceMenu);
    setOpenRateMenu(false);
  };

  const toggleRateMenu = () => {
    setOpenRateMenu(!openRateMenu);
    setOpenPriceMenu(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return {
    t,
    data,
    totalPages,
    viewProfile,
    currentPage,
    servicesName,
    subServiceID,
    subServicesData,
    subServicesName,
    handlePageClick,
    servicesProvider,
    scrollToSubServices,
    subServicesRef,
    setSubServicesName,
    setServiceProvider,
    subServicesLoading,
    servicesProviderLoading,
    openPriceMenu,
    setOpenPriceMenu,
    openRateMenu,
    setOpenRateMenu,
    togglePriceMenu,
    toggleRateMenu,
    locationID,
    setLocationID,
    setOpenSignIn,
    access_token,
  };
};
