import React, { useRef, useEffect } from "react";
import BookingCard from "./BookingCard";
import loader from "assets/icons/svg/chatLoader.svg";

const BookingList = ({
  data,
  type,
  isFetchingNextPage,
  isLoading,
  isFetching,
  hasNextPage,
  fetchNextPage,
  setOpen,
  changeStatus,
}) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const current = containerRef.current;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = current;

      if (shouldFetchMore(scrollTop, scrollHeight, clientHeight)) {
        fetchNextPage();
      }
    };

    const shouldFetchMore = (scrollTop, scrollHeight, clientHeight) => {
      return (
        scrollTop + clientHeight >= scrollHeight - 10 && // Add a threshold for fetching, adjust as needed
        !isFetchingNextPage &&
        !isLoading &&
        !isFetching &&
        hasNextPage
      );
    };

    if (current) {
      current?.addEventListener("scroll", handleScroll);

      return () => {
        current.removeEventListener("scroll", handleScroll);
      };
    }
  }, [fetchNextPage, isFetchingNextPage, isLoading, isFetching, hasNextPage]);

  return (
    <>
      <div
        ref={containerRef}
        className="max-h-[700px] overflow-scroll flex flex-col gap-5 w-full"
      >
        {data?.pages?.flatMap((item) => {
          const bookings =
            type === "last" ? item?.last_booking : item?.current_booking;
          return bookings?.map((booking) => (
            <BookingCard
              key={booking.id}
              booking={booking}
              setOpen={setOpen}
              changeStatus={changeStatus}
            />
          ));
        })}
      </div>
      {isFetchingNextPage ? <img src={loader} alt="" /> : null}
    </>
  );
};

export default BookingList;
