import checkIcon from "assets/icons/chat/checkicon.svg";
import photo from "assets/icons/chat/photo.svg";
import backIcon from "assets/icons/svg/Back.svg";
import DefaultImage from "assets/images/profile/defaultImage.png";
import { motion } from "framer-motion";
import Conversation from "../Conversation";

const MessagesResposnive = ({
  t,
  data,
  chatID,
  refetch,
  navigate,
  isLoading,
  setChatID,
  formatDate,
  totalUnreadCount,
  setTotalUnreadCount,
  handleSearch,
  handleDrawer,
  openDrawer,
}) => {
  return (
    <>
      {chatID ? (
        <div className="block w-full lg:hidden">
          <Conversation />
        </div>
      ) : (
        !isLoading && (
          <div className="flex flex-col items-start w-full h-full lg:hidden max-lg:mb-5">
            <div className="flex items-center justify-between w-full p-3 max-lg:mt-3 lg:p-5">
              <div className="flex w-10/12 gap-3">
                <button onClick={() => navigate("/")}>
                  <img src={backIcon} alt="" />
                </button>
                <div className="flex items-center justify-between ">
                  <p className="text-xl font-bold">{t("messages")}</p>
                </div>
              </div>
              <p className="flex items-center justify-center mt-2 mr-5 text-xs text-white rounded-full w-7 h-7 bg-fourth-color">
                {totalUnreadCount}
              </p>
              <div className="rotate-90 ">
                <span
                  className="text-[#AFB5C0] text-3xl  "
                  onClick={handleDrawer}
                >
                  ...
                </span>
              </div>

              <div className="absolute right-0 w-40 text-lg -translate-x-10 translate-y-16 bg-white rounded-lg rounded-tr-none shadow-2xl">
                {openDrawer && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeInOut", duration: 1.0 }}
                    className="flex flex-col gap-3 p-2 text-sm "
                  >
                    <p
                      onClick={() => {
                        navigate("/chat/message");
                      }}
                    >
                      {"Messages"}
                    </p>
                    <p
                      onClick={() => {
                        navigate("/chat/block");
                      }}
                    >
                      {"Blocked"}
                    </p>
                  </motion.div>
                )}
              </div>
            </div>
            <div className="w-full p-3 ">
              <input
                type="search"
                placeholder={t("search..")}
                onChange={handleSearch}
                className="w-full  p-2 bg-gray-200 rounded-md focus:outline-none focus:ring focus:ring-[#707A8F] placeholder:text-[#707A8F]"
              />
            </div>
            {/* MSG */}
            <div className="mt-5 max-h-[500px] overflow-scroll  w-full flex flex-col gap-5 message-scroll  ">
              {data?.conversations?.length > 0 ? (
                data?.conversations?.map((item, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeInOut", duration: 2.0 }}
                    className="flex gap-2 px-2 mt-2 cursor-pointer "
                    onClick={() => {
                      setChatID(item.id);
                      refetch();

                      setTotalUnreadCount(
                        (prevTotal) => prevTotal - item.unread_count
                      );
                    }}
                  >
                    <div className="w-1/3 mx-2 rounded-full ">
                      <img
                        src={item.user_image ? item.user_image : DefaultImage}
                        alt="user"
                        className="object-cover w-20 h-20 rounded-full ring-1 ring-primary-color ring-inset"
                      />
                    </div>
                    <div className="flex flex-col w-full gap-3 ">
                      <div className="flex justify-between ">
                        <p className="text-base font-semibold text-[#484E5B]">
                          {item.user_name}
                        </p>
                        <p className="text-[#707A8F] text-sm">
                          {formatDate(item.created_at)}
                        </p>
                      </div>
                      <div className="flex justify-between ">
                        <div className="text-sm text-[#2A3751] flex gap-2">
                          {item.last_message?.media ? (
                            <img
                              src={item.last_message?.media ? photo : ""}
                              alt=""
                              className="w-5"
                            />
                          ) : (
                            ""
                          )}
                          {item.last_message?.content ?? "No Messages"}
                          {item?.last_message?.is_sender === true ? (
                            <img src={checkIcon} alt="" />
                          ) : (
                            ""
                          )}
                        </div>
                        {chatID !== item.id ? (
                          <p
                            className={`${
                              item.unread_count === 0 ? "hidden" : "flex"
                            } flex items-center justify-center w-5 h-5 text-xs text-white rounded-full bg-fourth-color `}
                          >
                            {item.unread_count}
                          </p>
                        ) : (
                          <p className="flex items-center justify-center w-5 h-5 text-xs text-white rounded-full bg-fourth-color ">
                            {item.unread_count === 0}
                          </p>
                        )}
                      </div>
                    </div>
                  </motion.div>
                ))
              ) : (
                <div className="flex items-center justify-center w-full font-semibold ">
                  <p>{t("No Messages")}</p>
                </div>
              )}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default MessagesResposnive;
