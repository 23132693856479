import * as yup from "yup";

export const useValidation = () => {
  const schema = yup.object().shape({
    current_password: yup
      .string()
      .required("Old Password is required")
      .min(6, "The Password must be of six characters")
      .max(20, "The Password must be of 20 characters"),

    password: yup
      .string()
      .required("New Password is required")
      .min(6, "The Password must be of six characters")
      .max(20, "The Password must be of 20 characters"),

    password_confirmation: yup
      .string()
      .required("Confirm password is required")
      .min(6, "The confirm password must be of six characters")
      .max(20, "The confirm password must be of 20 characters")
      .oneOf([yup.ref("password")], "your password does not match"),
  });
  return {
    schema,
  };
};
