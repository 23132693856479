import { _axios } from "interceptor/http-config";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { searchStore } from "store/searchStore";

const SubServicesList = ({ setOpenSearchModal }) => {
  const subServicesList = searchStore((state) => state.subServicesList);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const hanldeProfileShow = (id) => {
    navigate(`/services/view/${id}`);
    _axios.get(`/user/service-providers/${id}`);
    setOpenSearchModal(false);
  };
  if (subServicesList.length === 0)
    return <p className="capitalize text-center text-xl">{t("no result")}</p>;
  return subServicesList.map((subService) => (
    <button
      key={subService.id}
      onClick={() => {
        hanldeProfileShow(subService.id);
        setOpenSearchModal(false);
      }}
      type="button"
      className="flex items-center w-full gap-10 p-4 my-4 border rounded-lg"
    >
      <img
        src={subService.image}
        className="w-20 h-20"
        alt={subService.name}
        loading="lazy"
      />
      <p className="text-xl font-medium text-primary-color line-clamp-1">
        {subService.name}
      </p>
    </button>
  ));
};

export default SubServicesList;
