import { _ReviewsApi } from "api/reviews/reviews";
import { useQuery } from "react-query";

export const useReviews = ({ id }) => {
  const { data, isLoading } = useQuery(
    ["review", id],
    () => _ReviewsApi.index({ id }).then((res) => res),
    {
      keepPreviousData: true,
    }
  );
  return {
    data,
    isLoading,
  };
};
