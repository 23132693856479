import { useFAQ } from "hooks/account/useFAQ";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
export const useLogic = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const { t } = useTranslation("index");
  const navigate = useNavigate();
  const { data, isLoading } = useFAQ();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return { t, activeIndex, setActiveIndex, data, isLoading, navigate };
};
