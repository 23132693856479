import ArrowIcon from "assets/icons/profile/Arrow.png";
import DeActivateIcon from "assets/icons/profile/changePassowrd.png";
import DeleteIcon from "assets/icons/profile/deleteIcon.svg";
import privacyIcon from "assets/icons/profile/privacy.png";
import backIcon from "assets/icons/svg/Back.svg";
import cookiesIcon from "assets/icons/svg/account/Cookies.svg";
import changePasswordIcon from "assets/icons/svg/account/changePassowrd.svg";
import termsIcon from "assets/icons/svg/account/terms.svg";
import DeActivate from "../components/DeActivate/DeActivate";
import DeleteAccount from "../components/DeleteAccount/DeleteAccount";
import { useSettingLogic } from "../hooks/useSettingLogic";
const Setting = () => {
  const {
    t,
    navigate,
    sectionRef,
    deActivate,
    setdeActivate,
    deleteAccount,
    setDeleteAccount,
  } = useSettingLogic();

  return (
    <div
      ref={sectionRef}
      className="flex flex-col items-center justify-center w-full h-full lg:flex-row lg:flex"
    >
      {deActivate && (
        <>
          <DeActivate setdeActivate={setdeActivate} />
        </>
      )}
      {deleteAccount && (
        <>
          <DeleteAccount setDeleteAccount={setDeleteAccount} />
        </>
      )}
      <div className="flex items-start w-full h-full lg:hidden">
        <div className="flex gap-5 p-5 mt-5" onClick={() => navigate(-1)}>
          <button>
            <img src={backIcon} alt="" />
          </button>
          <p className="text-xl font-bold">{t("Settings")}</p>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center w-full gap-10 px-5 mb-5 lg:h-screen 2xl:mx-10 2xl:px-32 2xl:my-0">
        <div className="flex flex-col w-full gap-16 bg-[#F4F4F7]  rounded-lg p-5 lg:px-10  px-4">
          <div className="flex justify-between w-full duration-1000 hover:px-4 ">
            <div
              onClick={() => navigate("change-password")}
              className="flex items-center justify-start w-full gap-2 text-center cursor-pointer lg:gap-5"
            >
              <img src={changePasswordIcon} alt="" className="w-7" />
              <p className="text-base font-medium">{t("Change Password")}</p>
            </div>
            <div className="flex items-center justify-end lg:w-1/2">
              <img src={ArrowIcon} alt="" className="w-2 md:w-1/1" />
            </div>
          </div>
          <div className="flex justify-between w-full duration-1000 hover:px-4 ">
            <div
              onClick={() => navigate("privacy-policy")}
              className="flex items-center justify-start w-full text-center cursor-pointer lg:gap-4"
            >
              <img src={privacyIcon} alt="" className="w-7" />
              <p className="text-base font-medium">{t("Privacy Policy")}</p>
            </div>
            <div className="flex items-center justify-end lg:w-1/2">
              <img src={ArrowIcon} alt="" className="w-2 md:w-1/1" />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full gap-16 bg-[#F4F4F7]  rounded-lg p-5 lg:px-10 px-4">
          <div className="flex justify-between w-full duration-1000 hover:px-4 ">
            <div
              onClick={() => setDeleteAccount(true)}
              className="flex items-center justify-start w-full gap-2 text-center duration-1000 cursor-pointer lg:gap-5 hover:px-4 "
            >
              <img src={DeleteIcon} alt="" className="w-7" />
              <p className="text-base font-medium">{t("Delete Account")}</p>
            </div>
          </div>
          <div className="flex justify-between w-full duration-1000 hover:px-4 ">
            <div
              onClick={() => setdeActivate(true)}
              className="flex items-center justify-start w-full gap-2 text-center duration-1000 cursor-pointer lg:gap-5 hover:px-4 "
            >
              <img src={DeActivateIcon} alt="" className="w-7" />
              <p className="text-base font-medium">{t("Deactivate Account")}</p>
            </div>
          </div>
          <div className="flex justify-between w-full duration-1000 hover:px-4 ">
            <div
              onClick={() => navigate("terms-conditions")}
              className="flex items-center justify-start w-full text-center cursor-pointer lg:gap-4"
            >
              <img src={termsIcon} alt="" className="w-6 mr-2 md:mr-0" />
              <p className="text-base font-medium">
                {t("Terms and Conditions")}
              </p>
            </div>
            <div className="flex items-center justify-end lg:w-1/2">
              <img src={ArrowIcon} alt="" className="w-2 md:w-1/1" />
            </div>
          </div>
          <div className="flex w-full duration-1000 hover:px-4 ">
            <div
              onClick={() => navigate("cookies")}
              className="flex items-center justify-start w-full text-center cursor-pointer lg:gap-4"
            >
              <img src={cookiesIcon} alt="" className="mr-2 w-7 md:mr-0" />
              <p className="text-base font-medium">{t("Cookies")}</p>
            </div>
            <div className="flex items-center justify-end lg:w-1/2">
              <img src={ArrowIcon} alt="" className="w-2 md:w-1/1" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
