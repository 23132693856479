import { useFavourites } from "hooks/favourites/useFavourites";
import { _axios } from "interceptor/http-config";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

const useFavorite = () => {
  const { t } = useTranslation('index');
  const navigate = useNavigate();
  const [favoriteStatus, setFavoriteStatus] = useState({});
  // Fetch the initial favorite data and update the state:
  const { data, isLoading } = useFavourites();
  const [loadingFavorite, setLoadingFavorite] = useState(false);
  const queryClient = useQueryClient();
  const favoriteToggle = useMutation(
    async (id) => {
      // Send the update to the server
      return _axios.get(`user/toggle-favourite/${id}`).then((response) =>
        enqueueSnackbar(response.data.message, {
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        })
      );
    },
    {
      onMutate: async (id) => {
        // Optimistically update the favoriteStatus
        const updatedFavoriteStatus = { ...favoriteStatus };
        updatedFavoriteStatus[id] = !updatedFavoriteStatus[id];
        setFavoriteStatus(updatedFavoriteStatus);
        await queryClient.cancelMutations(""); // Cancel any pending mutations for this key
        return id; // Pass the id as context for the onSettled function
      },
      onError: (error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const { message } = error.response.data;
          Object.keys(message).forEach((field) => {
            const errorMessages = message[field];
            alert(`${field}: ${errorMessages.join(", ")}`);
            window.location.reload();
          });
        }
      },
      onSettled: (id, error) => {
        // Handle success or error cases and clear loading state
        if (!error) {
          queryClient.invalidateQueries(["favourite"]);
        } else {
          // If there's an error, revert the optimistic update
          const updatedFavoriteStatus = { ...favoriteStatus };
          updatedFavoriteStatus[id] = !updatedFavoriteStatus[id];
          setFavoriteStatus(updatedFavoriteStatus);
        }
        setLoadingFavorite(false);
      },
    }
  );
  const handleFavorite = async (id) => {
    setLoadingFavorite(true);
    try {
      await favoriteToggle.mutateAsync(id);
    } catch (error) {
      console.error("Async mutation error:", error);
    }
  };
  const hanldeProfileShow = (id) => {
    navigate(`/service-provider/profile/${id}`);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return {
    hanldeProfileShow,
    handleFavorite,
    t,
    data,
    isLoading,
    loadingFavorite,
    favoriteStatus,
  };
};

export default useFavorite;
