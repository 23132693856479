import { useConfirmPassword } from "../hooks/useConfirmPassword";

const ConfirmPassword = ({
  email,
  name,
  loading,
  setLoading,
  confirmPassword,
  setConfirmPassword,
  setEditProfile,
}) => {
  const { t, handleSubmit, onSubmit, register, errors } = useConfirmPassword(
    email,
    name,
    loading,
    setLoading,
    confirmPassword,
    setConfirmPassword,
    setEditProfile
  );

  return (
    <>
      <div
        className="fixed top-0 left-0 !z-[100] w-screen h-screen bg-black/75"
        onClick={() => setConfirmPassword(!confirmPassword)}
      ></div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="absolute left-0 !z-[150] flex flex-col justify-center w-10/12 gap-20 p-1 mx-10 mt-40 bg-white rounded-lg lg:translate-x-10 top-10 lg:p-10 lg:justify-around lg:gap-0 h-96 lg:left-1/3 lg:mx-0 lg:w-1/4"
      >
        <p className="text-2xl text-[#213241] text-center">
          {t("Confirm Password")}
        </p>
        <div className="flex flex-col gap-2 px-2">
          <label className="">{t("Password")}</label>

          <input
            placeholder={t("Enter Password")}
            type={"password"}
            id="password"
            name="password"
            {...register("password")}
            className="w-full p-3 border border-primary-color rounded-3xl lg:p-5 "
          />

          {errors.password && (
            <span className="text-red-500">{errors.password.message}</span>
          )}
        </div>
        <div className="flex justify-center w-full gap-10 text-white">
          <button
            className="py-2 px-14 bg-primary-color rounded-xl"
            type="submit"
          >
            {t("Submit")}
          </button>
        </div>
      </form>
    </>
  );
};

export default ConfirmPassword;
