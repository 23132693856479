import {
  Autocomplete,
  // DirectionsRenderer,
  GoogleMap,
  LoadScript,
  MarkerF,
  Polygon,
} from "@react-google-maps/api";
import { useCallback, useMemo } from "react";
import { useEffect, useRef, useState } from "react";
import bavariaBorder from "assets/bavaria_border_data";
import axios from "axios";
import { useTranslation } from "react-i18next";

const BOUNDS = {
  north: 50.6,
  south: 47.2,
  west: 9.6,
  east: 14,
};
const Map = ({
  selectedLocation,
  setSelectedLocation,
  setOpen,
  setLocationName,
}) => {
  const [userLocation, setUserLocation] = useState({
    lat: 48.13625818375002,
    lng: 11.584020996093773,
  });

  const apiKey = "AIzaSyDKR1ZaGk-Is5GKCln82RM6375QlQwnUa4";

  const searchBoxRef = useRef(null);
  const mapRef = useRef(null);
  const { t } = useTranslation("index");
  const [zoom, setZoom] = useState(10);
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
          // setSelectedLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting user location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Escape") {
        if (!selectedLocation) return;
        setOpen(false);
      }
    },
    [setOpen, selectedLocation]
  );

  useEffect(() => {
    document?.addEventListener("keydown", handleKeyDown);

    return () => {
      document?.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleMapClick = async (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setSelectedLocation({ lat, lng });

    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
      );
      const address =
        response.data.plus_code?.compound_code?.slice(8) || "location selected";
      setLocationName(address);
    } catch (error) {
      setLocationName("location selected");
    }
  };
  const handlePlacesSearch = () => {
    const places = searchBoxRef?.current?.getPlace();
    if (!places?.geometry) return;
    if (places.geometry.location) {
      const lat = places.geometry.location.lat();
      const lng = places.geometry.location.lng();
      // setSelectedLocation({ lat: lat(), lng: lng() });
      // setDirections(null);
      setZoom(15);
      setUserLocation({ lat, lng });
    }
  };

  const memoizedBavariaBorder = useMemo(() => {
    return bavariaBorder;
  }, []);

  return (
    <div className="fixed z-50 flex items-center justify-center w-11/12 -translate-x-1/2 lg:w-2/3 aspect-square lg:aspect-video top-1/4 md:top-1/2 left-1/2 -translate-y-1/4 md:-translate-y-1/2 ">
      <div className="w-full h-full text-white rounded-md lg:w-full ">
        <div className="flex justify-between">
          <p className="my-2 text-xl lg:my-10">{t('Select Your location')}</p>
          {selectedLocation ? (
            <p
              className="my-2 text-xl cursor-pointer lg:my-10"
              onClick={() => setOpen(false)}
            >
              X
            </p>
          ) : null}
        </div>
        <LoadScript
          googleMapsApiKey="AIzaSyDKR1ZaGk-Is5GKCln82RM6375QlQwnUa4"
          libraries={["places"]}
        >
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "500px",
              borderRadius: "20px",
            }}
            onZoomChanged={() => {
              if (mapRef.current) {
                console.log(mapRef.current?.getZoom());
                setZoom(mapRef.current?.getZoom());
              }
            }}
            mapContainerClassName="map"
            zoom={zoom}
            onClick={() =>
              alert (t("please choose location inside the Bavaria, Germany"))
            }
            // center={{
            //   lat: 49.14275273727908,
            //   lng: 11.460424804687523,
            // }}
            center={userLocation}
            onLoad={(map) => (mapRef.current = map)}
            options={{
              zoomControl: true,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
              draggableCursor: false,
              restriction: {
                latLngBounds: {
                  north: 50.6, // North boundary (e.g., Bavaria)
                  south: 47.2, // South boundary (e.g., Bavaria)
                  west: 8.8, // West boundary (e.g., Bavaria)
                  east: 14, // East boundary (e.g., Bavaria)
                },
                strictBounds: false, // Disallow panning outside the bounds
              },
            }}
          >
            <div className="relative z-10">
              <Autocomplete
                onLoad={(ref) => (searchBoxRef.current = ref)}
                onPlaceChanged={handlePlacesSearch}
                options={{ strictBounds: true, bounds: BOUNDS }}
              >
                <input
                  type="text"
                  className="text-black"
                  placeholder="Search for a location"
                  style={{
                    boxSizing: "border-box",
                    border: "1px solid transparent",
                    width: "240px",
                    height: "50px",
                    padding: "0 12px",
                    borderRadius: "3px",
                    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.9)",
                    fontSize: "14px",
                    outline: "none",
                    textOverflow: "ellipses",
                    marginTop: "10px",
                    marginLeft: "10px",
                    userSelect: "none",
                  }}
                />
              </Autocomplete>
            </div>
            {/* {userLocation && (
                <MarkerF position={userLocation} title="Your Location" />
              )} */}
            {selectedLocation && <MarkerF position={selectedLocation} />}
            {/*} {directions && (
                <DirectionsRenderer
                  options={{
                    directions: directions,
                  }}
                />
                )} */}
            <Polygon
              paths={memoizedBavariaBorder}
              onClick={handleMapClick}
              options={{
                strokeColor: "red",
                strokeOpacity: 0.8,
                strokeWeight: 1,
                fillColor: "red",
                fillOpacity: 0.2,
              }}
            />
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
};

export default Map;
