import React from "react";
import { useTranslation } from "react-i18next";
import { searchStore } from "store/searchStore";

const SearchEmpty = () => {
  const setOpenSearchModal = searchStore((state) => state.setOpenSearchModal);
  const { t } = useTranslation('index');
  return (
    <div
      onClick={() => setOpenSearchModal(false)}
      className="fixed left-0 flex z-[999999] items-center justify-center w-screen h-screen top-20 right-20 bg-black/50"
    >
      <div className="fixed top-[10%] left-10 right-10 flex lg:justify-around gap-20 lg:gap-0 justify-center bg-white h-1/1 p-10 rounded-lg shadow-xl z-[9999]">
        <div className="flex items-center justify-center w-full h-1/2">
          {t('No Search Result')}
        </div>
      </div>
    </div>
  );
};

export default SearchEmpty;
