import heartEmpty from "assets/icons/heartEmpty.svg";
import heartFill from "assets/icons/heartFill.svg";
import useFavorite from "../hooks/useFavorite";

const FavoriteCard = ({ favorite }) => {
  const { favoriteStatus, hanldeProfileShow, handleFavorite } = useFavorite();
  return (
    <div className="flex items-center justify-between gap-2 p-2 text-center border border-white cursor-pointer h-28 md:h-40 lg:p-5 rounded-3xl backdrop-blur-md">
      <div
        onClick={() => hanldeProfileShow(favorite.id)}
        className="flex items-center w-full gap-2 lg:gap-10"
      >
        <img
          src={favorite.image}
          alt=""
          className="object-cover w-16 h-16 lg:w-32 lg:h-28 rounded-3xl"
        />
        <p className="text-base lg:text-xl">{favorite.name}</p>
      </div>
      <div>
        <img
          src={favoriteStatus[favorite.id] ? heartEmpty : heartFill}
          alt=""
          onClick={() => handleFavorite(favorite.id)}
          className="w-5 h-5 lg:h-10 lg:w-10"
        />
      </div>
    </div>
  );
};

export default FavoriteCard;
