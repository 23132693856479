import Loader from "components/styled/Loader";
import { useSettingLogic } from "../../hooks/useSettingLogic";

const DeleteAccount = ({ setDeleteAccount }) => {
  const { t, deleteLoading, handleDeleteAccount } = useSettingLogic();
  return (
    <>
      <div
        onClick={() => setDeleteAccount(false)}
        className="fixed top-0 left-0 flex items-center justify-center w-screen h-screen bg-black/50"
      ></div>
      <div className="fixed top-[25%]  flex flex-col lg:justify-around gap-20 lg:gap-0 justify-center z-50 bg-white h-60  p-1 rounded-lg lg:left-1/3 left-0 mx-10 lg:mx-0  md:w-1/3 2xl:w-1/4 ">
        {deleteLoading ? (
          <Loader />
        ) : (
          <>
            <p className="text-2xl text-[#213241] text-center">
              {t("Are you Sure you want to Delete your Account")}
            </p>
            <div className="flex justify-center w-full gap-10 text-white">
              <button
                onClick={handleDeleteAccount}
                className="py-2 px-14 bg-primary-color rounded-xl"
              >
                {t("Yes")}
              </button>
              <button
                onClick={() => setDeleteAccount(false)}
                className="py-2 border px-14 border-primary-color rounded-xl text-primary-color"
              >
                {t("No")}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DeleteAccount;
