import Singin from "pages/Auth/Singin/Singin";

const LoginModal = ({
  openSignIn,
  setOpenSignIn,
  openSignUp,
  setOpenSignUp,
}) => {
  const hanldeOpenSignIn = () => {
    setOpenSignIn(!openSignIn);
  };
  return (
    openSignIn && (
      <>
        <Singin
          setOpenSignIn={setOpenSignIn}
          openSignUp={openSignUp}
          setOpenSignUp={setOpenSignUp}
        />
        <div
          className="fixed top-0 z-40 w-screen h-screen bg-black/75"
          onClick={hanldeOpenSignIn}
        ></div>
      </>
    )
  );
};

export default LoginModal;
