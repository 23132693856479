import logo from "assets/icons/logo.png";
import Loader from "components/styled/Loader";
import { useResetPassword } from "./hooks/useResetPassword";
import { Swiper, SwiperSlide } from "swiper/react";
import img from "assets/images/Auth/sss.jpg";
import img2 from "assets/images/Auth/ImgRegister.png";
import img3 from "assets/images/Auth/OO.jpg";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
const ResetPassword = () => {
  const {
    t,
    loading,
    navigate,
    handleSubmit,
    onSubmit,
    register,
    setPhone,
    errors,
    phone,
  } = useResetPassword();
  return (
    <div>
      {loading && <Loader />}

      <div className="fixed top-0 z-50 flex items-center justify-center w-full h-full">
        <div
          onClick={() => navigate(-1)}
          className="fixed top-0 z-30 w-screen h-screen cursor-pointer bg-black/75"
        ></div>
        <div className="z-50 flex items-center justify-center w-full md:w-auto">
          <div className="flex justify-center w-full">
            {/* hidden on responsive */}
            <div
              className={`text-white hidden md:flex overflow-hidden md:w-[290px] rounded-l-2xl relative z-10 `}
            >
              <Swiper
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay, Pagination]}
                className="overflow-hidden mySwiper"
              >
                <SwiperSlide>
                  <img
                    src={img}
                    className="w-full object-cover max-h-[600px] md:max-h-[700px]"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img2}
                    className="w-full object-cover max-h-[600px] md:max-h-[700px]"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img3}
                    className="w-full object-cover max-h-[600px] md:max-h-[700px]"
                    alt=""
                  />
                </SwiperSlide>
              </Swiper>
            </div>
            {/* hidden on responsive */}
            <div
              className={`md:rounded-r-xl min-h-[600px] w-full max-h-[600px] md:max-h-[700px] relative z-10
                  flex flex-col rounded-2xl md:rounded-none overflow-auto scrollbar-hide bg-white md:w-[575px] p-3 md:p-5 max-md:m-3 md:m-0`}
            >
              <div className="flex justify-end w-full">
                <p
                  className="p-2 font-bold text-[18px] cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  X
                </p>
              </div>
              <div className="flex flex-col items-center justify-start w-full h-full md:p-10">
                {/* <div className="flex flex-col items-center justify-center gap-10 md:hidden">
                  <img src={logo} alt="mibo" className="w-1/2" />

                  <p>
                    {t(
                      "We just need you registered Email ID to send your password reset instructions."
                    )}
                  </p>
                </div> */}
                <div className="flex flex-col items-center justify-center mb-3">
                  <p className="text-[24px] mb-3 font-bold ">Mibo</p>
                  <p className="text-center">
                    {t(
                      "We just need you registered Email ID to send your password reset instructions."
                    )}
                  </p>
                </div>

                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="flex flex-col justify-center w-full gap-4 mt-10 md:mt-0 md:gap-10"
                >
                  {/* <img src={logo} alt="mibo" className="w-1/2" />

                  <p>
                    {t(
                      "We just need you registered Email ID to send your password reset instructions."
                    )}
                  </p> */}
                  <div className="flex flex-col gap-2 lg:gap-5">
                    <label className="text-[#666666] max-md:text-[12px]">
                      {t("Phone")}
                    </label>
                    <input
                      type="text"
                      placeholder={t("Enter your phone number")}
                      {...register("phone", { validate: true })}
                      id="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      className="p-2.5 md:p-3 border border-primary-color max-md:text-[12px] rounded-xl md:rounded-lg"
                    />
                    {errors.phone && (
                      <span
                        className="field_level_error"
                        style={{ color: "red" }}
                      >
                        {errors.phone.message}
                      </span>
                    )}
                  </div>

                  <div className="flex flex-col items-center justify-center w-full">
                    <button
                      type="submit"
                      className="relative w-full h-12 text-white rounded-3xl bg-primary-color"
                      disabled={loading}
                    >
                      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                        {t("Send Code")}
                      </div>
                    </button>

                    <div className="flex items-center max-md:text-[12px] gap-1 justify-center mt-3">
                      <div className="text-[#666666]">
                        {t("By continuing, you agree to the")}
                      </div>
                      <a
                        href="/settings/terms-conditions"
                        className="font-[400] underline"
                      >
                        {" "}
                        {t("Terms of Service")}
                      </a>
                    </div>
                    <div className="flex items-center gap-1 max-md:text-[12px] justify-center mb-3">
                      <div className="text-[#666666]">
                        {t("and acknowledge you’ve read our")}
                      </div>
                      <a className="font-[400] underline">
                        {" "}
                        {t("Privacy Policy.")}
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
