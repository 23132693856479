import { _AuthApi } from "api/auth";
import bookingIcon from "assets/icons/profile/booking.png";
import faqIcon from "assets/icons/profile/faq.png";
import getSupportIcon from "assets/icons/profile/getsupport.png";
import profileIcon from "assets/icons/profile/profile.png";
import settingIcon from "assets/icons/profile/setting.png";
import profileIconMob from "assets/icons/svg/account/profile.svg";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ImageDataContext } from "store/ImageDataContext";
import { editProfileStore } from "store/editProfile";
import bookingIconMob from "assets/icons/svg/account/bookings.svg";
import faqIconMob from "assets/icons/svg/account/faq.svg";
import getSupportIconMob from "assets/icons/svg/account/getSupport.svg";
import settingIconMob from "assets/icons/svg/account/settings.svg";
import { useProfile } from "hooks/profile/useProfile";

const imageExtensions = ["image/png", "image/jpg", "image/jpeg"];

export const useSideBar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("account");
  const { data } = useProfile();

  const [sideBarActive, setSideBarActive] = useState(false);
  const [lougoutLoading, setlougoutLoading] = useState(false);
  const [editProfile, setEditProfile] = editProfileStore((state) => [
    state.editProfile,
    state.setEditProfile,
  ]);

  const { imagesData, setImagesData } = useContext(ImageDataContext);

  const links = [
    { img: profileIcon, title: t("Profile"), navigate: "profile" },
    { img: bookingIcon, title: t("Bookings"), navigate: "bookings" },
    { img: settingIcon, title: t("Settings"), navigate: "settings" },
    { img: faqIcon, title: t("FAQ’s"), navigate: "faq" },
    { img: getSupportIcon, title: t("Get Support"), navigate: "get-support" },
  ];
  const responsiveLinks = [
    { img: profileIconMob, title: t("Profile"), navigate: "mobile/profile" },
    { img: bookingIconMob, title: t("Bookings"), navigate: "mobile/bookings" },
    { img: settingIconMob, title: t("Settings"), navigate: "mobile/settings" },
    { img: faqIconMob, title: t("FAQ’s"), navigate: "mobile/faq" },
    {
      img: getSupportIconMob,
      title: t("Get Support"),
      navigate: "mobile/get-support",
    },
  ];
  const handleLogout = (input) => {
    setlougoutLoading(true);
    _AuthApi
      .destroyToken(input)
      ?.then(() => {
        navigate("/");
        setlougoutLoading(true);
      })
      ?.finally(() => {
        navigate("/");
        setlougoutLoading(false);
      });
  };

  const handleImage = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      if (file) {
        if (!imageExtensions.includes(file.type)) {
          alert("File type not supported");
          return;
        }
        reader.onload = (e) => {
          // Find the index of the existing image data
          const index = imagesData.findIndex((image) => image.Image);
          if (index !== -1) {
            // Update the existing image data
            setImagesData((prev) => {
              const updatedImagesData = [...prev];
              updatedImagesData[index] = {
                Image: file,
                ImageLink: e.target.result,
              };
              return updatedImagesData;
            });
          } else {
            // Add new image data
            setImagesData((prev) => [
              ...prev,
              { Image: file, ImageLink: e.target.result },
            ]);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };
  const imageLink = imagesData.find((item) => item.ImageLink);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return {
    t,
    links,
    data,
    navigate,
    imageLink,
    editProfile,
    handleImage,
    handleLogout,
    sideBarActive,
    lougoutLoading,
    responsiveLinks,
    setEditProfile,
    setSideBarActive,
  };
};
